<template>
	<div class="ce-wrap-content" :class="getClass()">
		<div v-if="showDeleteControl" class="ce-remove-config">
			<p>{{ $t('ceDelete') }}?</p>
			<button @click="$emit('removeConfig', config.id)" class="ce-yes">{{ $t('ceYes') }}</button>
			<button @click="handleClick($event, 'close')" class="ce-no">{{ $t('ceNo') }}</button>
		</div>
		<div :id="config.id" class="ce-wrap-config" @click="handleClick($event, 'expand')">
			<i v-if="config.visType == 'chart'" class="fas fa-chart-line ce-chart-icon" :title="$t('coLineChart')"></i>
			<i v-else-if="config.visType == 'map'" class="fas fa-map ce-chart-icon" :title="$t('coMap')"></i>

			<div class="ce-preview-text">
				<p v-if="config.dataType == 'gt'">Google Trends</p>
				<p v-else-if="config.dataType == 'twitter'">Twitter</p>
				<p v-else-if="config.dataType == 'combo'">{{ $t('coCombined') }} {{ $t('coOf') }}</p>
				<p v-else-if="config.dataType == 'rki'">RKI</p>

				<p v-if="config.mapType == 'ttds'">{{ $t('coTrendDailyState') }}</p>
				<p v-else-if="config.mapType == 'ttdc'">{{ $t('coTrendDailyCity') }}</p>
				<p v-else-if="config.mapType == 'ttss'">{{ $t('coTrendSummedState') }}</p>
				<p v-else-if="config.mapType == 'ttsc'">{{ $t('coTrendSummedCity') }}</p>
				<p v-else-if="config.mapType == 'rtds'">{{ $t('coTrendDailyState') }}</p>
				<p v-else-if="config.mapType == 'rtss'">{{ $t('coTrendSummedState') }}</p>

				<p v-if="config.chartType == 'classifier'">{{ $t('coTweetClassifier') }}</p>
				<p v-else-if="config.chartType == 'frequency'">{{ $t('coTweetFrequency') }}</p>
				<p v-else-if="config.chartType == 'single'">{{ $t('coGTSingle') }}</p>
				<p v-else-if="config.chartType == 'multi'">{{ $t('coGTMulti') }}</p>
				<p v-else-if="config.chartType == 'classifier-single'">{{ $t('coTweetClassifier') }} & {{ $t('coGTSingle') }}</p>
				<p v-else-if="config.chartType == 'classifier-multi'">{{ $t('coTweetClassifier') }} & {{ $t('coGTMulti') }}</p>
				<p v-else-if="config.chartType == 'frequency-single'">{{ $t('coTweetFrequency') }} & {{ $t('coGTSingle') }}</p>
				<p v-else-if="config.chartType == 'frequency-multi'">{{ $t('coTweetFrequency') }} & {{ $t('coGTMulti') }}</p>

				<p v-if="config.selectedClassifiers.length > 0 && config.selectedKeywords.length > 0">
					{{ $t('clClassifiers') }}({{ config.selectedClassifiers.length }}), {{ $t('klKeywords') }}({{
						config.selectedKeywords.length
					}})
				</p>
				<p v-else-if="config.selectedKeywords.length > 0">{{ $t('klKeywords') }}({{ config.selectedKeywords.length }})</p>
				<p v-else-if="config.selectedClassifiers.length > 0">
					{{ $t('clClassifiers') }}({{ config.selectedClassifiers.length }})
				</p>
			</div>

			<div class="ce-wrap-info">
				<p v-if="config.chartType.split('-')[0] == 'frequency'">{{ $t('coTweetFrequencyInfo') }}</p>

				<p v-if="config.mapType == 'ttds'">{{ $t('coTrendDailyStateInfo') }}</p>
				<p v-else-if="config.mapType == 'ttdc'">{{ $t('coTrendDailyCityInfo') }}</p>
				<p v-else-if="config.mapType == 'ttss'">{{ $t('coTrendSummedStateInfo') }}</p>
				<p v-else-if="config.mapType == 'ttsc'">{{ $t('coTrendSummedCityInfo') }}</p>
				<p v-else-if="config.mapType == 'rtds'">{{ $t('coTrendDailyStateInfo') }}</p>
				<p v-else-if="config.mapType == 'rtss'">{{ $t('coTrendDailyCityInfo') }}</p>

				<p v-if="config.selectedClassifiers.length > 0">
					{{ $t('clClassifiers') }}:
					<!-- prettier-ignore -->
					{{config.selectedClassifiers.map((cl) => {return this.$t(cl.name);}).join(', ')}}
				</p>
				<p v-if="config.selectedKeywords.length > 0">{{ $t('klKeywords') }}: {{ config.selectedKeywords.join(', ') }}</p>
			</div>
			<i class=""></i>
			<i v-if="queryFailed" class="fas fa-exclamation-triangle ce-query-error" title="ceQueryFailed"></i>
			<i class="fas fa-times-circle ce-delete" @click="handleClick($event, 'remove')"></i>
		</div>
	</div>
</template>

<script>
/**
 * @group Prediction
 * A generated config element with additional information
 */
export default {
	name: 'ConfigElement',
	props: {
		// The generated config
		config: {
			type: Object,
			required: true,
		},
		// A check if the data could be queried or not
		queryFailed: {
			type: Boolean,
			required: true,
		},
	},
	watch: {
		queryFailed: {
			handler: function (newVal) {
				let element = document.getElementById(this.config.id).parentElement;
				if (newVal) element.classList.add('ce-failed');
				else element.classList.remove('ce-failed');
			},
		},
	},
	data() {
		return {
			extended: false,
			showDeleteControl: false,
		};
	},
	methods: {
		// @vuese
		// Checks the config and returns the data type
		// @return [String] - The name of the data
		getClass() {
			if (this.config.dataType == 'twitter') return 'ce-twitter';
			else if (this.config.dataType == 'gt') return 'ce-gt';
			else if (this.config.dataType == 'combo') return 'ce-combo';
			else if (this.config.dataType == 'rki') return 'ce-rki';
		},
		// @vuese
		// Handels clicks on the config element
		// @arg e[Object] - The event that occured
		// @arg type[String] - Depends on the element clicked
		handleClick(e, type) {
			e.stopPropagation();
			if (type == 'expand') this.extendConfig();
			else if (type == 'remove') this.removeConfig();
			else if (type == 'close') this.showDeleteControl = false;
		},
		// @vuese
		// Expands or collapsed the coonfig element
		extendConfig() {
			let div = document.getElementById(this.config.id);
			if (this.extended) div.style.height = 25 + 'px';
			else div.style.height = div.scrollHeight + 'px';

			this.extended = !this.extended;
		},
		// @vuese
		// Shows the delete buttons for the config element
		removeConfig() {
			this.showDeleteControl = !this.showDeleteControl;
		},
	},
};
</script>

<style scoped>
.ce-wrap-content {
	position: relative;
	margin-bottom: 10px;
	-webkit-box-shadow: 0 6px 15px 0px var(--main-color-border-dark);
	box-shadow: 0 6px 15px 0px var(--main-color-border-dark);
}

.ce-remove-config {
	position: absolute;
	right: 5px;
	top: -60px;
	z-index: 3;
	padding: 5px;
	border-radius: 10px;
	background-color: var(--main-color-1-transparent);
	-webkit-box-shadow: 0 0px 15px 0px var(--main-color-border-dark);
	box-shadow: 0 0px 15px 0px var(--main-color-border-dark);
}

.ce-twitter {
	border-bottom: 5px solid var(--main-color-twitter) !important;
}
.ce-gt {
	border-bottom: 5px solid var(--main-color-gt) !important;
}
.ce-combo {
	border-bottom: 5px solid var(--main-color-combo) !important;
}
.ce-rki {
	border-bottom: 5px solid var(--main-color-rki) !important;
}

.ce-failed {
	border-bottom: 5px solid var(--main-color-error) !important;
}

.ce-yes,
.ce-no {
	width: 50px;
	height: 30px;
	margin: 2px;
	padding: 2px;
}

.ce-yes {
	background-color: var(--main-color-error);
}

.ce-yes:hover {
	background-color: var(--secondary-color-error) !important;
}

.ce-no {
	background-color: var(--main-color-4);
}

.ce-no:hover {
	background-color: var(--main-color-4-transparent) !important;
}

.ce-wrap-config {
	height: 25px;
	padding: 5px 35px;
	position: relative;
	background-color: var(--main-color-4);
	color: var(--main-color-text-light);
	overflow: hidden;
	-webkit-transition: height 0.5s ease-out;
	-moz-transition: height 0.5s ease-out;
	transition: height 0.5s ease-out;
	z-index: 2;
}

.ce-wrap-config:hover {
	background-color: var(--main-color-2);
	color: var(--main-color-text-dark);
	cursor: pointer;
}

.ce-wrap-config:hover .ce-delete:hover {
	color: var(--main-color-1);
}

.ce-chart-icon {
	position: absolute;
	left: 5px;
	top: 5px;
	font-size: 25px;
}

.ce-preview-text {
	max-height: 25px;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 25px;
}

.ce-preview-text p {
	margin: 0px 3px;
	display: inline-block;
	font-size: 17px;
}

.ce-delete {
	position: absolute;
	right: 5px;
	top: 5px;
	font-size: 25px;
	/* color: var(--main-color-error); */
}

.ce-delete:hover {
	color: var(--main-color-1);
}

.ce-query-error {
	position: absolute;
	right: 35px;
	top: 5px;
	font-size: 25px;
	font-weight: bold;
	color: var(--main-color-warning);
}

.ce-wrap-info {
	margin-top: 10px;
}

@media (pointer: none), (pointer: coarse) {
	.ce-preview-text {
		max-height: 25px;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 25px;
	}

	.ce-preview-text p {
		margin: 0px 1px;
		display: inline-block;
		font-size: 12px;
	}
}
</style>
