<template>
	<div id="chartWrapper" class="ch-wrap-content" @contextmenu="resetZoom">
		<!-- <canvas id="lineChart" width="400" height="600"></canvas> -->
		<LineChart
			v-if="showChart"
			:chartData="config.data"
			:options="config.options"
			:height="getHeight()"
			@chart:render="setChart"
		/>
	</div>
</template>

<script>
import { LineChart, useLineChart } from 'vue-chart-3';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import { defineComponent } from '@vue/composition-api';
import zoomPlugin from 'chartjs-plugin-zoom';
Chart.register(...registerables, zoomPlugin);
/**
 * @group Prediction
 * The line chart that displays all data over the time
 */
export default defineComponent({
	name: 'Chart',
	components: {
		LineChart,
	},
	props: {
		// The config of the visualization
		config: {
			type: Object,
			required: true,
		},
		// Toggles and clears the chart on every change
		clear: {
			type: Boolean,
			required: true,
		},
	},
	watch: {
		config: {
			handler: function (newVal) {
				this.showChart = true;
			},
			deep: true,
		},
		clear: {
			handler: function () {
				this.showChart = false;
				this.chart = null;
			},
		},
	},
	data() {
		return {
			showChart: false,
			chart: null,
		};
	},
	created() {
		// const data = this.config.data;
		// const options = this.config.options;
		// const { lineChartProps, lineChartRef } = useLineChart({
		// 	data,
		// 	options,
		// });
		// this.chartData = lineChartProps;
	},
	mounted() {
		this.showChart = true;
	},
	methods: {
		// @vuese
		// Computes the height that the chart can take
		// @return [Number] - The available height
		getHeight() {
			let height = window.innerHeight - 250;
			return height < 250 ? 250 : height;
		},
		// @vuese
		// Sets the chart for further reference
		// @arg chart[Object] - The created chart
		setChart(chart) {
			this.chart = chart;
		},
		// @vuese
		// Resets the zoom in the chart
		// @arg e[Object] - The event that occured
		resetZoom(e) {
			e.preventDefault();
			this.chart.resetZoom();
		},
	},
});
</script>

<style scoped>
.ch-wrap-content {
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin: 10px 0px;
	padding: 5px;
	border-radius: 10px;
	background-color: var(--main-color-1);
	-webkit-box-shadow: 0px 0px 20px 10px var(--main-color-border-dark);
	box-shadow: 0px 0px 20px 10px var(--main-color-border-dark);
}
</style>
