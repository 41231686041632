<template>
	<div class="he-wrap-content" @mouseenter="removeTransparency" @mouseleave="setTransparency">
		<div class="he-wrap-logo he-transparent">
			<img
				v-if="$route.name == 'Site'"
				:src="require(`@/assets/Logo_700x691.png`)"
				:alt="$t('heLogo')"
				@click="scrollTo('#Home')"
			/>
			<img
				v-else-if="$route.name != 'Site'"
				:src="require(`@/assets/Logo_700x691.png`)"
				:alt="$t('heLogo')"
				@click="navigateTo('Site')"
			/>
		</div>
		<div class="he-wrap-header he-transparent">
			<div class="he-all-elements">
				<template v-if="$route.name == 'Site'">
					<p class="he-header-element he-dark-text" @click="scrollTo('#Home')">{{ $t('heHome') }}</p>
					<p class="he-header-element he-dark-text" @click="scrollTo('#Background')">{{ $t('heBackground') }}</p>
					<p class="he-header-element he-dark-text" @click="scrollTo('#Prediction')">{{ $t('hePrediction') }}</p>
					<p class="he-header-element he-dark-text" @click="scrollTo('#Statistics')">{{ $t('heStatistics') }}</p>
					<router-link class="he-header-element he-dark-text" to="/LegalNotice">{{ $t('heImpressum') }}</router-link>
				</template>
				<template v-else-if="$route.name == 'LegalNotice'">
					<router-link class="he-header-element he-dark-text" to="/">{{ $t('heHome') }}</router-link>
					<!-- <router-link class="he-header-element he-dark-text" to="/LegalNotice">{{ $t('heImpressum') }}</router-link> -->
				</template>
				<template v-else>
					<router-link class="he-header-element he-dark-text" to="/">{{ $t('heHome') }}</router-link>
					<router-link class="he-header-element he-dark-text" to="/LegalNotice">{{ $t('heImpressum') }}</router-link>
				</template>
			</div>
			<div class="he-collapsed-elements">
				<i class="fas fa-bars he-menu-icon" @click="showMenu = !showMenu"></i>
				<div v-if="showMenu" class="he-header-menu">
					<div v-if="$route.name == 'Site'" class="he-relative">
						<i class="fas fa-times" @click="showMenu = false"></i>
						<div class="he-menu-element">
							<p @click="scrollTo('#Home')">{{ $t('heHome') }}</p>
						</div>
						<div class="he-menu-element">
							<p @click="scrollTo('#Background')">{{ $t('heBackground') }}</p>
						</div>
						<div class="he-menu-element">
							<p @click="scrollTo('#Prediction')">{{ $t('hePrediction') }}</p>
						</div>
						<div class="he-menu-element">
							<p @click="scrollTo('#Statistics')">{{ $t('heStatistics') }}</p>
						</div>
						<div class="he-menu-element">
							<router-link to="/LegalNotice" @click.native="showMenu = false">{{ $t('heImpressum') }}</router-link>
						</div>
					</div>
					<div v-else-if="$route.name == 'LegalNotice'" class="he-relative">
						<i class="fas fa-times" @click="showMenu = false"></i>
						<div class="he-menu-element">
							<router-link to="/" @click.native="showMenu = false">{{ $t('heHome') }}</router-link>
						</div>
						<!-- <div  class="he-menu-element">
							<router-link to="/LegalNotice" @click.native="showMenu = false">{{ $t('heImpressum') }}</router-link>
						</div> -->
					</div>
					<div v-else class="he-relative">
						<i class="fas fa-times" @click="showMenu = false"></i>
						<div class="he-menu-element">
							<router-link to="/" @click.native="showMenu = false">{{ $t('heHome') }}</router-link>
						</div>
						<div class="he-menu-element">
							<router-link to="/LegalNotice" @click.native="showMenu = false">{{ $t('heImpressum') }}</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="he-wrap-languages">
			<img
				id="de"
				class="he-selected-language"
				:src="require(`@/assets/german.svg`)"
				:alt="$t('heGerman')"
				@click="setLanguage('de')"
			/>
			<img id="en" :src="require(`@/assets/english.svg`)" :alt="$t('heEnglish')" @click="setLanguage('en')" />
		</div>
	</div>
</template>

<script>
import i18n from '@/translations/i18n';
/**
 * @group Layout
 * The header of the page
 */
export default {
	name: 'Header',
	data() {
		return {
			showMenu: false,
		};
	},
	methods: {
		// @vuese
		// Removes the transparency evect of the header
		removeTransparency() {
			document.getElementsByClassName('he-wrap-header')[0].classList.remove('he-transparent');
			document.getElementsByClassName('he-wrap-logo')[0].classList.remove('he-transparent');
		},
		// @vuese
		// Adds the transparency evect of the header
		setTransparency() {
			document.getElementsByClassName('he-wrap-header')[0].classList.add('he-transparent');
			document.getElementsByClassName('he-wrap-logo')[0].classList.add('he-transparent');
		},
		// @vuese
		// Change the language used
		// @arg lang[String] - The selected language
		setLanguage(lang) {
			document.getElementById(lang).classList.add('he-selected-language');
			if (lang == 'de') document.getElementById('en').classList.remove('he-selected-language');
			else document.getElementById('de').classList.remove('he-selected-language');
			i18n.locale = lang;
			window.dispatchEvent(new Event('languageChange'));
		},
		// @vuese
		// Scrolls the view to a certain destination
		// @arg dest[String] - The location hash
		scrollTo(dest) {
			this.showMenu = false;
			location.hash = dest;
		},
		navigateTo(dest) {
			this.$router.push({
				name: dest,
			});
		},
	},
};
</script>

<style scoped>
.he-wrap-content {
	width: calc(100% - 17px);
	height: 75px;
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: var(--main-color-5-transparent);
	z-index: 1000;
}

/* .he-transparent {
	opacity: 0.5;
} */

.he-wrap-logo {
	width: 60px;
	height: 60px;
	margin: 10px;
	display: inline-block;
	cursor: pointer;
}

.he-wrap-logo img {
	width: 100%;
	height: 100%;
}

.he-wrap-header {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: 75px;
	display: inline-block;
	vertical-align: top;
}

.he-header-element {
	margin: 25px 10px;
	display: inline-block;
	font-size: 20px;
	text-decoration: none;
	color: var(--main-color-1);
	/* background-color: var(--main-color-1-transparent); */
}

.he-header-element:hover {
	cursor: pointer;
	text-decoration: underline;
}

/* .he-light-text {
	color: var(--main-color-1) !important;
}

.he-dark-text {
	color: var(--main-color-5) !important;
} */

.he-wrap-languages {
	width: 100px;
	height: 75px;
	position: absolute;
	top: 0px;
	right: 0px;
}

.he-wrap-languages img {
	width: 40px;
	height: 24px;
	margin: 27px 5px;
	display: inline-block;
	cursor: pointer;
	opacity: 0.5;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
	background-color: white;
	vertical-align: top;
}

.he-wrap-languages img:hover {
	border: 2px solid var(--main-color-1);
	margin: 25px 3px;
	opacity: 1;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

.he-selected-language {
	border: 2px solid var(--main-color-5);
	margin: 25px 3px !important;
	opacity: 1 !important;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
	pointer-events: none;
}

.he-collapsed-elements {
	height: 100%;
	display: none;
	position: relative;
	font-size: 30px;
	color: var(--main-color-5);
}

.he-menu-icon {
	position: absolute;
	top: 25px;
	left: 5px;
	color: var(--main-color-1);
}

.he-menu-icon:hover {
	cursor: pointer;
	color: var(--main-color-2);
}

.he-header-menu {
	position: absolute;
	top: 28px;
	left: 40px;
	padding: 0px 15px;
	background-color: var(--main-color-3);
	border-radius: 5px;
	-webkit-box-shadow: 0px 0px 5px 2px var(--main-color-border-dark);
	box-shadow: 0px 0px 5px 2px var(--main-color-border-dark);
}

.he-relative {
	width: 100%;
	height: 100%;
	padding: 20px 0px;
	position: relative;
	display: block;
}

.he-relative i {
	position: absolute;
	top: 2px;
	right: -10px;
	color: var(--main-color-1);
}

.he-relative i:hover {
	cursor: pointer;
	color: var(--main-color-2);
}

.he-menu-element {
	width: calc(100% - 20px);
	margin: 5px auto;
	padding: 5px;
	text-align: center;
	font-size: 20px;
	border-bottom: 1px solid var(--main-color-1);
	cursor: pointer;
}

.he-menu-element a {
	color: var(--main-color-1);
	text-decoration: none;
}

.he-menu-element p {
	color: var(--main-color-1);
	text-decoration: none;
}

.he-menu-element:hover {
	border-bottom: 3px solid var(--main-color-2);
	padding: 5px 5px 3px 5px !important;
}

.he-menu-element:hover a {
	color: var(--main-color-2);
}

.he-menu-element:hover p {
	color: var(--main-color-2);
}

@media screen and (max-width: 750px) {
	.he-all-elements {
		display: none;
	}

	.he-collapsed-elements {
		display: inline-block;
	}
}

@media (pointer: none), (pointer: coarse) {
	.he-relative {
		width: 100%;
		height: 100%;
		padding: 5px 0px;
		position: relative;
		display: block;
	}
	.he-menu-element {
		padding: 5px;
		text-align: center;
		font-size: 10px;
	}
	.he-relative i {
		font-size: 20px;
	}
}

@media (pointer: none), (pointer: coarse) {
	.he-wrap-content {
		width: calc(100% - 12px);
	}
}
</style>
