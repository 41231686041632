<template>
	<div id="Statistics" class="st-wrap-content">
		<a name="Statistics"></a>
		<h2>{{ $t('stStatistics') }}</h2>
		<div v-if="configs">
			<div>
				<h3 v-if="generalConfigs.length > 0">
					{{ $t('stGeneral') }}
					<i class="fas fa-window-minimize" @click="minimizeStats($event, 'generalConfigs')"></i>
					<i class="fas fa-window-maximize st-hidden" @click="maximizeStats($event, 'generalConfigs')"></i>
				</h3>
				<StatisticsList v-if="generalConfigs.length > 0" id="generalConfigs" :generalConfigs="generalConfigs" />
			</div>
			<div>
				<h3 v-if="chartConfigs.length > 0">
					{{ $t('stLineChart') }}
					<i class="fas fa-window-minimize" @click="minimizeStats($event, 'chartConfigs')"></i>
					<i class="fas fa-window-maximize st-hidden" @click="maximizeStats($event, 'chartConfigs')"></i>
				</h3>
				<StatisticsList v-if="chartConfigs.length > 0" id="chartConfigs" :chartConfigs="chartConfigs" />
			</div>
			<div>
				<h3 v-if="mapConfigs.length > 0">
					{{ $t('stMap') }}
					<i class="fas fa-window-minimize" @click="minimizeStats($event, 'mapConfigs')"></i>
					<i class="fas fa-window-maximize st-hidden" @click="maximizeStats($event, 'mapConfigs')"></i>
				</h3>
				<StatisticsList v-if="mapConfigs.length > 0" id="mapConfigs" :mapConfigs="mapConfigs" />
			</div>
		</div>
		<div v-else>
			<p class="st-no-data">
				{{ $t('stNoData') }}
			</p>
		</div>
		<a name="Statistics"></a>
	</div>
</template>

<script>
import StatisticsList from '../components/statistics/StatisticsList.vue';
/**
 * @group Statistics
 * Contains the statistics of the queried data
 */
export default {
	name: 'Statistics',
	components: {
		StatisticsList,
	},
	props: {
		// The generated configs for the data query
		configs: {
			type: Array,
			required: false,
		},
		// The data that is correlated
		correlationData: {
			type: Object,
			required: false,
		},
	},
	watch: {
		configs: {
			handler: function (newVal) {
				if (newVal) this.initializeStatistics();
			},
		},
		correlationData: {
			handler: function (newVal) {
				if (newVal) this.computeCorrelations();
			},
		},
	},
	data() {
		return {
			generalConfigs: [],
			chartConfigs: [],
			mapConfigs: [],
		};
	},
	created() {
		window.addEventListener('resize', this.resizeListener);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeListener);
	},
	methods: {
		// @vuese
		// Initializes the statistics and determines which data is corrolated with which
		initializeStatistics() {
			let that = this;
			let classifiers = [];
			this.generalConfigs = [];
			this.chartConfigs = [];
			this.mapConfigs = [];
			let isChart, isMap, isTwitter, isRKI, isFrequency, isClassifier;

			this.configs.forEach((config) => {
				if (config.visType == 'chart') isChart = true;
				if (config.visType == 'map') isMap = true;
				if (config.dataType == 'twitter') isTwitter = true;
				if (config.dataType == 'combo') isTwitter = true;
				if (config.dataType == 'rki') isRKI = true;
				if (config.chartType.includes('frequency')) isFrequency = true;
				if (config.chartType.includes('classifier')) isClassifier = true;
			});

			if (isClassifier) {
				this.configs.forEach((config) => {
					if (config.selectedClassifiers.length > 0 && config.dataType !== 'combo') {
						classifiers = [...classifiers, ...config.selectedClassifiers];
					}
				});
				classifiers = [...new Set(classifiers)];
			}

			if (isTwitter) {
				this.$global.getData('statistics', '/generalStatistics', { config: { isMap: isMap } }, function (err, result) {
					if (err) that.$global.showToast('error', `${err.response.status}: ${that.$t(err.response.data.msg)}`);
					else {
						that.generalConfigs = result;
					}
				});
			}
			if (isChart && isTwitter) {
				this.$global.getData(
					'statistics',
					'/twitterChartStatistics',
					{ config: { isFrequency: isFrequency, isClassifier: isClassifier, classifiers: classifiers } },
					function (err, result) {
						if (err) that.$global.showToast('error', `${err.response.status}: ${that.$t(err.response.data.msg)}`);
						else {
							result.forEach((conf) => {
								let stack = that.chartConfigs.find((c) => c.type == conf.extra.type);
								if (stack) stack.statistic.push(conf);
								else
									that.chartConfigs.push({
										id: that.$uuid.v4(),
										type: conf.extra.type,
										statistic: [conf],
									});
							});
						}
					}
				);
			}
			if (isMap && isTwitter) {
				this.$global.getData(
					'statistics',
					'/twitterMapStatistics',
					{ config: { isFrequency: isFrequency, isClassifier: isClassifier, classifiers: classifiers } },
					function (err, result) {
						if (err) that.$global.showToast('error', `${err.response.status}: ${that.$t(err.response.data.msg)}`);
						else {
							result.forEach((conf) => {
								let stack = that.mapConfigs.find((c) => c.type == conf.extra.type);
								if (stack) stack.statistic.push(conf);
								else
									that.mapConfigs.push({
										id: that.$uuid.v4(),
										type: conf.extra.type,
										statistic: [conf],
									});
							});
						}
					}
				);
			}
			if (isMap && isRKI) {
				this.$global.getData('statistics', '/rkiMapStatistics', null, function (err, result) {
					if (err) that.$global.showToast('error', `${err.response.status}: ${that.$t(err.response.data.msg)}`);
					else {
						result.forEach((conf) => {
							let stack = that.mapConfigs.find((c) => c.type == conf.extra.type);
							if (stack) stack.statistic.push(conf);
							else
								that.mapConfigs.push({
									id: that.$uuid.v4(),
									type: conf.extra.type,
									statistic: [conf],
								});
						});
					}
				});
			}
		},
		// @vuese
		// Requests the data for the correlations
		computeCorrelations() {
			let rkiData = this.correlationData.rkiData.find((rki) => rki.visType == 'chart');
			let correlationConfigs = [];
			this.configs.forEach((config) => {
				if (config.visType == 'chart') {
					let correlation = null;
					if (config.dataType == 'twitter') {
						if (config.chartType == 'frequency') {
							let data = this.correlationData.twitterData.find((d) => d.id == config.id);
							if (data) {
								correlation = this.prepareCorrelation(rkiData, data.result);
								correlationConfigs.push({ ...correlation, ...config });
							}
						} else if (config.chartType == 'classifier') {
							let twitterConfigs = this.correlationData.twitterData.find((d) => d.id == config.id);
							if (twitterConfigs) {
								twitterConfigs.result.forEach((conf, idx) => {
									correlation = this.prepareCorrelation(rkiData, conf.data);
									correlationConfigs.push({
										...correlation,
										id: config.id,
										dataType: config.dataType,
										chartType: config.chartType,
										selectedClassifiers: [config.selectedClassifiers[idx]],
										selectedKeywords: [],
									});
								});
							}
						}
					} else if (config.dataType == 'gt') {
						if (config.chartType == 'single') {
							let gtConfigs = this.correlationData.gtData.find((d) => d.id == config.id);
							if (gtConfigs) {
								gtConfigs.result.forEach((conf, idx) => {
									correlation = this.prepareCorrelation(rkiData, conf.data);
									correlationConfigs.push({
										...correlation,
										id: config.id,
										dataType: config.dataType,
										chartType: config.chartType,
										selectedClassifiers: [],
										selectedKeywords: [config.selectedKeywords[idx]],
									});
								});
							}
						} else if (config.chartType == 'multi') {
							let gtConfigs = this.correlationData.gtData.find((d) => d.id == config.id);
							if (gtConfigs) {
								correlation = this.prepareCorrelation(rkiData, gtConfigs.result.data);
								correlationConfigs.push({ ...correlation, ...config });
							}
						}
					} else if (config.dataType == 'combo') {
						let comboConfigs = this.correlationData.comboData.find((d) => d.id == config.id);
						if (comboConfigs) {
							comboConfigs.result.forEach((conf) => {
								correlation = this.prepareCorrelation(rkiData, conf.data);
								correlationConfigs.push({
									...correlation,
									id: config.id,
									dataType: config.dataType,
									chartType: config.chartType,
									selectedClassifiers: config.selectedClassifiers,
									selectedKeywords: config.selectedKeywords,
									name: conf.name,
								});
							});
						}
					}
				}
			});
			if (this.correlationData.gtData.length > 0) this.createStatistic(this.correlationData.gtData);
			if (this.correlationData.comboData.length > 0) this.createStatistic(this.correlationData.comboData);
			let that = this;
			if (correlationConfigs.length > 0) {
				console.log(correlationConfigs);
				let preparedData = {
					groundtruth: correlationConfigs[0].groundtruth,
					configs: correlationConfigs.map((conf) => {
						return {
							id: conf.id,
							data: conf.comparison,
							dataType: conf.dataType,
							chartType: conf.chartType,
							selectedClassifiers: conf.selectedClassifiers,
							selectedKeywords: conf.selectedKeywords,
							name: conf.name,
						};
					}),
				};
				console.log(preparedData);
				this.$global.postData('statistics', '/correlation', preparedData, function (err, result) {
					console.log(result);
					if (err) that.$global.showToast('error', `${err.response.status}: ${that.$t(err.response.data.msg)}`);
					else {
						result.forEach((res) => {
							let type = '';
							if (res.chartType == 'frequency') type = 'frequency';
							else if (res.chartType == 'classifier') type = res.selectedClassifiers[0].name;
							else if (res.chartType == 'single') type = res.selectedKeywords[0];
							else if (res.chartType == 'multi') type = res.selectedKeywords.join(', ');
							else if (res.dataType == 'combo') {
								res.name[0] = that.$t(res.name[0]);
								type = res.name.join(', ');
							}
							// cor - correlation
							// cpv - correlationPValue
							let pearson = {
								type: 'cor',
								value: res.pearson,
								extra: { type: type },
							};
							let stack = that.chartConfigs.find((c) => c.type == pearson.extra.type);
							if (stack) stack.statistic.push(pearson);
							else {
								that.chartConfigs.push({
									id: that.$uuid.v4(),
									type: pearson.extra.type,
									statistic: [pearson],
								});
							}

							let pValue = {
								type: 'cpv',
								value: res.pValue,
								extra: { type: type },
							};

							stack = that.chartConfigs.find((c) => c.type == pValue.extra.type);
							if (stack) stack.statistic.push(pValue);
							else {
								that.chartConfigs.push({
									id: that.$uuid.v4(),
									type: pValue.extra.type,
									statistic: [pValue],
								});
							}
						});
						that.$emit('statisticsLoaded');
					}
				});
			} else this.$emit('statisticsLoaded');
		},
		// @vuese
		// Prepares the groundtruth and the data for the correlation by matching the dates of each datapoint
		// @arg groundtruth[Array] - The groundtruth rki data
		// @arg data[Array] - The correlated data
		// @return [Object] - The matched data
		prepareCorrelation(groundtruth, data) {
			let daylist = [];
			if (new Date() < Date.parse('2023-02-13T00:00:00'))
				daylist = this.$global.getDaysArray(new Date('2019-12-29'), new Date());
			else if (new Date() >= Date.parse('2023-02-13T00:00:00'))
				daylist = this.$global.getDaysArray(new Date('2019-12-29'), Date.parse('2023-02-13'));

			let corD = { groundtruth: [], comparison: [] };
			daylist.forEach((day) => {
				let ground = groundtruth.result.find((g) => new Date(g.timestamp).getTime() == day.getTime());
				if (ground) corD.groundtruth.push(ground.cases);
				else corD.groundtruth.push(0);

				let da = data.find((d) => new Date(d.timestamp).getTime() == day.getTime());
				if (da) corD.comparison.push(da.value ? da.value : da.tweets ? da.tweets : da.infected ? da.infected : 0);
				else corD.comparison.push(0);
			});

			return corD;
		},
		// @vuese
		// Combines the statistics for the gt and combo data configs
		// @arg configs[Array] - The configs from which the statistics are created of
		createStatistic(configs) {
			let statistics = [];
			configs.forEach((conf) => {
				if (conf.dataType == 'gt') {
					if (conf.chartType == 'single') {
						conf.result.forEach((keyword) => {
							let values = keyword.data.map((val) => val.value);
							// gtl - gtLow
							// gth - gtHigh
							// gta - gtAverage
							statistics.push({
								type: 'gtl',
								value: Math.min(...values),
								extra: { type: keyword.keyword },
							});
							statistics.push({
								type: 'gth',
								value: Math.max(...values),
								extra: { type: keyword.keyword },
							});
							statistics.push({
								type: 'gta',
								value: Math.round((values.reduce((a, b) => a + b, 0) / values.length) * 100) / 100,
								extra: { type: keyword.keyword },
							});
						});
					} else if (conf.chartType == 'multi') {
						let values = conf.result.data.map((val) => val.value);
						// gtl - gtLow
						// gth - gtHigh
						// gta - gtAverage
						statistics.push({
							type: 'gtl',
							value: Math.min(...values),
							extra: { type: conf.result.keywords.join(', ') },
						});
						statistics.push({
							type: 'gth',
							value: Math.max(...values),
							extra: { type: conf.result.keywords.join(', ') },
						});
						statistics.push({
							type: 'gta',
							value: Math.round((values.reduce((a, b) => a + b, 0) / values.length) * 100) / 100,
							extra: { type: conf.result.keywords.join(', ') },
						});
					}
				} else if (conf.dataType == 'combo') {
					conf.result.forEach((combo) => {
						let values = combo.data.map((val) => val.value);
						let name = combo.name;
						name[0] = this.$t(name[0]);
						let type = name.join(', ');
						// col - comboLow
						// coh - comboHigh
						// coa - comboAverage
						statistics.push({
							type: 'col',
							value: Math.min(...values),
							extra: { type: type },
						});
						statistics.push({
							type: 'coh',
							value: Math.max(...values),
							extra: { type: type },
						});
						statistics.push({
							type: 'coa',
							value: Math.round((values.reduce((a, b) => a + b, 0) / values.length) * 100) / 100,
							extra: { type: type },
						});
					});
				}
			});
			// let statistic = stats.filter((value, index, self) => index === self.findIndex((t) => t.type === value.type));

			statistics.forEach((stats) => {
				let stack = this.chartConfigs.find((c) => c.type == stats.extra.type);
				if (stack) stack.statistic.push(stats);
				else {
					this.chartConfigs.push({
						id: this.$uuid.v4(),
						type: stats.extra.type,
						statistic: [stats],
					});
				}
			});
		},
		// @vuese
		// Hides the statistics that are selected
		// @arg e[Object] - The event that occured
		// @arg id[Number] - The id of the hidden statistic stack
		minimizeStats(e, id) {
			let stats = document.getElementById(id);
			stats.style.height = stats.offsetHeight + 'px';
			window.setTimeout(function () {
				stats.style.height = '0px';
				stats.style.overflow = 'hidden';

				e.target.classList.add('st-hidden');
				e.target.nextSibling.classList.remove('st-hidden');
			}, 100);
		},
		// @vuese
		// Shows the statistics that are selected
		// @arg e[Object] - The event that occured
		// @arg id[Number] - The id of the shown statistic stack
		maximizeStats(e, id) {
			let stats = document.getElementById(id);
			stats.style.height = 'fit-content';
			this.$nextTick(() => {
				let height = stats.offsetHeight;
				stats.style.height = '0px';
				window.setTimeout(function () {
					stats.style.height = height + 'px';
					window.setTimeout(() => {
						stats.style.overflow = null;
						stats.style.height = 'fit-content';
					}, 1000);
				}, 100);
			});

			e.target.classList.add('st-hidden');
			e.target.previousSibling.classList.remove('st-hidden');
		},
		// @vuese
		// Resize event to adjust layout and parts of the component
		// resizeListener() {
		// [...document.getElementsByClassName('sl-wrap-content')].forEach((element) => {
		// 	element.style.height = 'fit-content';
		// 	this.$nextTick(() => {
		// 		let height = element.offsetHeight;
		// 		window.setTimeout(function () {
		// 			element.style.height = height + 'px';
		// 		}, 100);
		// 	});
		// });
		// },
	},
};
</script>

<style scoped>
.st-wrap-content {
	background-color: var(--main-color-4);
	-webkit-box-shadow: 0px 0px 30px 30px black;
	box-shadow: 0px 0px 50px 10px black;
	text-align: center;
	padding: 30px 20px;
}

h2,
h3 {
	position: relative;
	padding: 0px 30px;
	color: var(--main-color-1);
}

h3 i {
	position: absolute;
	font-size: 20px;
	top: 10px;
	right: 10px;
	color: var(--main-color-1-transparent);
}

h3 i:hover {
	cursor: pointer;
	color: var(--main-color-1);
}

.st-hidden {
	display: none;
}

.st-no-data {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin: 10px 0px;
	padding: 10px;
	font-size: 18px;
	color: var(--main-color-1);
}
</style>
