<template>
	<div id="Prediction" class="pr-wrap-content">
		<a name="Prediction"></a>
		<h2>{{ $t('prPrediction') }}</h2>
		<!-- <div class="pr-wrap-info">
			<h3>
				{{ $t('prNextQuery') }}
				<i class="fas fa-info-circle" @click="$emit('showInfo', { title: 'prNextQuery', text: 'prNextQueryInfo' })"></i>
			</h3>
			<div class="pr-wrap-countdown"><Countdown :type="'twitter'" /></div>
			<div class="pr-wrap-countdown"><Countdown :type="'gt'" /></div>
			<div class="pr-wrap-countdown"><Countdown :type="'rki'" /></div>
		</div> -->
		<div class="pr-wrap-config">
			<Config
				:classifiers="classifiers"
				:keywords="keywords"
				:queryDisabled="queryDisabled"
				:loadingToggle="loadingToggle"
				:failedQuerys="failedQuerys"
				@queryData="queryData"
				@showInfo="$emit('showInfo', $event)"
			/>
		</div>
		<div class="pr-wrap-visualizations">
			<div v-if="chartConfig" class="pr-wrap-chart">
				<div class="pr-wrap-header">
					<h3>{{ $t('coLineChart') }}</h3>
					<i class="fas fa-file-download" @click="downloadCanvas('chart')"></i>
					<i
						class="fas fa-info-circle"
						@click="$emit('showInfo', { title: 'prChartInteraction', text: 'prChartInteractionInfo' })"
					></i>
				</div>
				<Chart :config="chartConfig" :clear="clearHook" />
				<!-- <div class="pr-wrap-timeframe">
					<div class="pr-wrap-input">
						<label>{{ $t('prStart') }}</label>
						<input type="date" v-model="dataStart" :max="dataEnd" @focusout="changeDataRange" />
					</div>
					<div class="pr-wrap-input">
						<label>{{ $t('prEnd') }}</label>
						<input type="date" v-model="dataEnd" :min="dataStart" @focusout="changeDataRange" />
					</div>
				</div> -->
			</div>
			<div v-if="mapConfig" class="pr-wrap-map">
				<div class="pr-wrap-header">
					<h3>{{ $t('coMap') }}</h3>
					<i class="fas fa-file-download" @click="downloadCanvas('map')"></i>
					<i
						class="fas fa-info-circle"
						@click="$emit('showInfo', { title: 'prMapInteraction', text: 'prMapInteractionInfo' })"
					></i>
				</div>
				<div v-if="mapConfigs.length > 0" class="pr-map-configs">
					<div
						v-for="(conf, idx) in mapConfigs"
						:key="`maps-${conf.id}`"
						class="pr-map-config"
						:class="idx == 0 ? 'pr-conf-selected' : ''"
						@click="setMapConfig($event, conf)"
					>
						{{ getConfigDescription(conf) }}
					</div>
				</div>
				<Map :config="mapConfig" />
			</div>
		</div>

		<a name="Prediction"></a>
	</div>
</template>

<script>
import Config from '../components/prediction/Config.vue';
import Chart from '../components/prediction/Chart.vue';
import Map from '../components/prediction/Map.vue';
import Countdown from '../components/prediction/Countdown.vue';
/**
 * @group Prediction
 * Contains the configuration of the visualization and the line chart and map
 */
export default {
	name: 'Prediction',
	components: {
		Config,
		Chart,
		Map,
		Countdown,
	},
	props: {
		// Tells the component if the statistics are fully loaded
		statisticsReady: {
			type: Boolean,
			required: true,
		},
	},
	watch: {
		statisticsReady: {
			handler: function () {
				if (this.chartReady) this.loadingToggle = !this.loadingToggle;
			},
		},
	},
	emits: ['showInfo'],
	data() {
		return {
			classifiers: [],
			keywords: [],
			failedQuerys: [],
			queryDisabled: false,
			loadingToggle: false,
			chartReady: false,
			queriedRKI: [],
			computedRKI: [],
			queriedTwitter: [],
			computedTwitter: [],
			queriedGT: [],
			computedGT: [],
			queriedCombo: [],
			computedCombo: [],
			chartConfig: null,
			colorCount: 0,
			highlight: '',
			clearHook: false,
			dataStart: '',
			dataEnd: '',
			mapConfigs: [],
			mapConfig: null,
		};
	},
	created() {
		let that = this;
		this.$global.getData('data', '/classifiers', null, function (err, result) {
			if (err) this.$global.showToast('error', err.response.data.msg);
			else that.classifiers = result;
		});

		this.$global.getData('data', '/keywords', null, function (err, result) {
			if (err) this.$global.showToast('error', err.response.data.msg);
			else that.keywords = result;
		});

		// window.addEventListener('languageChange', this.languageChange);
	},
	mounted() {
		// window.setTimeout(() => {
		// 	this.languageChange();
		// }, 1000);
		let min = new Date('2019-12-29');
		let max = new Date();
		this.dataStart = min.toISOString().substring(0, 10);
		this.dataEnd = max.toISOString().substring(0, 10);
	},
	// beforeDestroy() {
	// 	window.removeEventListener('languageChange', this.languageChange);
	// },
	methods: {
		// @vuese
		// Checks if the language of the page changed and adjusts the countdown translations
		// languageChange() {
		// 	let clocks = [...document.getElementsByClassName('flip-clock__piece')];
		// 	clocks.forEach((clock) => {
		// 		let text = clock.querySelector('.flip-clock__slot');
		// 		if (clock.id.includes('hours')) text.innerText = this.$t('prHours');
		// 		else if (clock.id.includes('minutes')) text.innerText = this.$t('prMinutes');
		// 		else if (clock.id.includes('seconds')) text.innerText = this.$t('prSeconds');
		// 	});
		// },
		// @vuese
		// Clears the chart before the reload
		clearChart() {
			this.clearHook = !this.clearHook;
		},
		// @vuese
		// Querys data depending on the generated configs
		// @arg configs[Array] - The generated configs
		queryData(configs) {
			// Sets the config in the statistics view
			this.$emit('setConfigs', configs);
			this.chartReady = false;
			this.queryDisabled = true;
			this.failedQuerys = [];
			let twitterConfigs = [];
			let gtConfigs = [];
			let comboConfigs = [];
			let rkiConfigs = [];

			configs.forEach((config) => {
				if (config.dataType == 'twitter') twitterConfigs.push(config);
				else if (config.dataType == 'gt') gtConfigs.push(config);
				else if (config.dataType == 'combo') comboConfigs.push(config);
				else if (config.dataType == 'rki') rkiConfigs.push(config);
			});

			if (gtConfigs.length > 0) rkiConfigs.push({ visType: 'chart' });
			else if (comboConfigs.length > 0) rkiConfigs.push({ visType: 'chart' });
			else if (twitterConfigs.filter((conf) => conf.visType == 'chart').length > 0) rkiConfigs.push({ visType: 'chart' });

			const rkiData = new Promise((resolve, reject) => {
				if (rkiConfigs.length > 0) {
					this.getData('/rkiData', rkiConfigs, function (result) {
						if (result) resolve(result);
						else resolve([]);
					});
				} else resolve([]);
			});

			const twitterData = new Promise((resolve, reject) => {
				if (twitterConfigs.length > 0) {
					this.getData('/twitterData', twitterConfigs, function (result) {
						if (result) resolve(result);
						else resolve([]);
					});
				} else resolve([]);
			});

			const gtData = new Promise((resolve, reject) => {
				if (gtConfigs.length > 0) {
					this.getData('/gtData', gtConfigs, function (result) {
						if (result) resolve(result);
						else resolve([]);
					});
				} else resolve([]);
			});

			const comboData = new Promise((resolve, reject) => {
				if (comboConfigs.length > 0) {
					this.getData('/comboData', comboConfigs, function (result) {
						if (result) resolve(result);
						else resolve([]);
					});
				} else resolve([]);
			});

			// TODO: Check for returned errors
			Promise.all([rkiData, twitterData, gtData, comboData]).then((results) => {
				this.clearChart();
				this.chartConfig = null;
				this.mapConfig = null;
				this.mapConfigs = [];
				this.colorCount = 0;
				this.highlight = '';

				let rkiError = results[0].filter((res) => res.error);
				let twitterError = results[1].filter((res) => res.error);
				let gtError = results[2].filter((res) => res.error);
				let comboError = results[3].filter((res) => res.error);

				console.log('DataErrors:', [...rkiError, ...twitterError, ...gtError, ...comboError]);

				this.failedQuerys = [...rkiError, ...twitterError, ...gtError, ...comboError].map((error) => error.id);
				if (this.failedQuerys.length > 0) this.$global.showToast('error', this.$t('prQueryError'));

				this.queriedRKI = results[0].filter((res) => !res.error);
				this.queriedTwitter = results[1].filter((res) => !res.error);
				this.queriedGT = results[2].filter((res) => !res.error);
				this.queriedCombo = results[3].filter((res) => !res.error);

				this.$nextTick(() => {
					this.createChart([...twitterConfigs, ...gtConfigs, ...comboConfigs, ...rkiConfigs]);
					this.chartReady = true;
					// Emits the data that needs to be correlated
					this.$emit('correlationData', {
						twitterData: this.queriedTwitter,
						gtData: this.queriedGT,
						comboData: this.queriedCombo,
						rkiData: this.queriedRKI,
					});
				});
			});
		},
		// @vuese
		// Queries the data for a specific config and route
		// @arg route[String] - The route that is targeted
		// @arg data[Object] - The config that is requested
		// @arg cb[Callback] - The callback back to the parent function
		getData(route, data, cb) {
			let that = this;
			this.$global.getData('data', route, { config: JSON.stringify(data) }, function (err, result) {
				if (err) {
					that.$global.showToast('error', `${err.response.status}: ${err.response.data.msg}`);
					cb(null);
				} else {
					cb(result);
				}
			});
		},
		// @vuese
		// Creates the chart from the created configs with the requested data
		// @arg configs[Array] - The configs
		createChart(configs) {
			let mapConfigs = [];
			let chartConfigs = [];
			configs.forEach((conf) => {
				if (conf.visType == 'chart') chartConfigs.push(conf);
				else if (conf.visType == 'map') mapConfigs.push(conf);
			});

			if (chartConfigs.length > 0) {
				let daylist = [];
				if (new Date() < Date.parse('2023-02-13T00:00:00'))
					daylist = this.$global.getDaysArray(new Date('2019-12-29'), new Date());
				else if (new Date() >= Date.parse('2023-02-13T00:00:00'))
					daylist = this.$global.getDaysArray(new Date('2019-12-29'), Date.parse('2023-02-13'));

				this.computedRKI = this.createRKIData(daylist);
				this.computedTwitter = this.createTwitterData(daylist);
				this.computedGT = this.createGTData(daylist);
				this.computedCombo = this.createComboData();
				let datasets = this.generateDatasets(this.computedRKI, this.computedTwitter, this.computedGT, this.computedCombo);
				let config = {
					type: 'line',
					data: {
						labels: daylist.map((day) => {
							return this.$global.parseDate(day);
						}),
						datasets: datasets,
					},
					options: {
						maintainAspectRatio: false,
						responsive: true,
						normalized: true,
						animation: false,
						elements: {
							point: {
								radius: 0,
								hitRadius: 3,
								hoverRadius: 5,
								backgroundColor: '#ffffff',
								borderColor: '#000000',
							},
							line: {
								borderWidth: 2,
								tension: 0, // disables bezier curves
							},
						},
						plugins: {
							zoom: {
								zoom: {
									wheel: {
										enabled: true,
									},
									pinch: {
										enabled: true,
									},
									mode: 'x',
								},
								pan: {
									enabled: true,
									mode: 'x',
								},
							},
						},
					},
				};

				this.chartConfig = config;
				this.$nextTick(() => {
					this.queryDisabled = false;
					this.colorCount = 0;
					this.highlight = '';
				});
			}

			if (mapConfigs.length > 0) {
				mapConfigs.forEach((config) => {
					if (config.dataType == 'rki') {
						this.mapConfigs.push({
							id: this.$uuid.v4(),
							mapType: config.mapType,
							dataType: config.dataType,
							chartType: config.chartType,
							data: this.queriedRKI.find((data) => data.mapType == config.mapType).result,
						});
					} else if (config.dataType == 'twitter') {
						if (config.chartType == 'frequency') {
							this.mapConfigs.push({
								id: this.$uuid.v4(),
								mapType: config.mapType,
								dataType: config.dataType,
								chartType: config.chartType,
								data: this.queriedTwitter.find((data) => data.id == config.id).result,
							});
						} else if (config.chartType == 'classifier') {
							config.selectedClassifiers.forEach((classifier) => {
								this.mapConfigs.push({
									id: this.$uuid.v4(),
									mapType: config.mapType,
									dataType: config.dataType,
									chartType: config.chartType,
									classifier: classifier,
									data: this.queriedTwitter
										.find((data) => data.id == config.id)
										.result.find((r) => r.classifier.classifier == classifier.classifier).data,
								});
							});
						}
					}
				});

				this.mapConfig = this.mapConfigs[0];
				this.$nextTick(() => {
					this.queryDisabled = false;
					this.colorCount = 0;
					this.highlight = '';
				});
			}
		},
		// @vuese
		// Creates the data structure for the rki data
		// @arg daylist[Array] - List with all days since the start of the pandemic
		// @return [Array] - The generated data structure
		createRKIData(daylist) {
			let rkiData = [];
			let today = this.$global.parseDate(new Date());
			let data = this.queriedRKI.find((d) => d.visType == 'chart');
			daylist.forEach((day) => {
				let rkiValue = data.result.find((val) => new Date(val.timestamp).getTime() == new Date(day).getTime());
				// There is no data for the current day
				if (this.$global.parseDate(day) !== today) {
					if (rkiValue) rkiData.push(rkiValue.cases);
					else rkiData.push(0);
				}
			});
			return rkiData;
		},
		// @vuese
		// Creates the data structure for the twitter data
		// @arg daylist[Array] - List with all days since the start of the pandemic
		// @return [Array] - The generated data structure
		createTwitterData(daylist) {
			let twitterData = [];
			this.queriedTwitter.forEach((config) => {
				if (config.visType == 'chart' && config.chartType == 'frequency') {
					let frequency = { type: config.chartType, name: 'coTweetFrequency', data: [] };
					daylist.forEach((day) => {
						let twitterValue = config.result.find((val) => new Date(val.timestamp).getTime() == new Date(day).getTime());
						if (twitterValue) frequency.data.push(twitterValue.tweets);
						else frequency.data.push(0);
					});
					twitterData.push(frequency);
				} else if (config.visType == 'chart' && config.chartType == 'classifier') {
					config.result.forEach((set) => {
						let classifier = { type: config.chartType, name: set.classifier.name, infected: [], notInfected: [] };
						daylist.forEach((day) => {
							let twitterValue = set.data.find((val) => new Date(val.timestamp).getTime() == new Date(day).getTime());
							if (twitterValue) {
								classifier.infected.push(twitterValue.infected);
								classifier.notInfected.push(twitterValue.notInfected);
							} else {
								classifier.infected.push(0);
								classifier.notInfected.push(0);
							}
						});
						twitterData.push(classifier);
					});
				}
			});

			return twitterData;
		},
		// @vuese
		// Creates the data structure for the gt data
		// @arg daylist[Array] - List with all days since the start of the pandemic
		// @return [Array] - The generated data structure
		createGTData(daylist) {
			let today = this.$global.parseDate(new Date());
			let gtData = [];
			this.queriedGT.forEach((config) => {
				if (config.visType == 'chart' && config.chartType == 'single') {
					config.result.forEach((set) => {
						let keyword = { name: set.keyword, data: [] };
						daylist.forEach((day) => {
							let gtValue = set.data.find((val) => new Date(val.timestamp).getTime() == new Date(day).getTime());
							if (gtValue) keyword.data.push(gtValue.value);
							else if (this.$global.parseDate(day) !== today) keyword.data.push(0);
						});
						gtData.push(keyword);
					});
				} else if (config.visType == 'chart' && config.chartType == 'multi') {
					let multi = { name: config.result.keywords.join(','), data: [] };
					daylist.forEach((day) => {
						let gtValue = config.result.data.find((val) => new Date(val.timestamp).getTime() == new Date(day).getTime());
						if (gtValue) multi.data.push(gtValue.value);
						else if (this.$global.parseDate(day) !== today) multi.data.push(0);
					});
					gtData.push(multi);
				}
			});

			return gtData;
		},
		// @vuese
		// Creates the data structure for the combo data
		// @return [Array] - The generated data structure
		createComboData() {
			let comboData = [];

			this.queriedCombo.forEach((config) => {
				if (config.visType == 'chart') {
					config.result.forEach((res) => {
						comboData.push(res);
					});
				}
			});
			return comboData;
		},
		// @vuese
		// Generated the datasets for the chart for each data source
		// @arg rkiData[Array] - The rki data
		// @arg twitterData[Array] - The twitter data
		// @arg gtData[Array] - The gt data
		// @arg comboData[Array] - The combo data
		generateDatasets(rkiData, twitterData, gtData, comboData) {
			let datasets = [];
			let config = {};

			datasets.push({
				label: 'RKI',
				borderColor: this.$global.getColor(`--main-color-chart-${this.colorCount}${this.highlight}`),
				lineBackgroundColor: this.$global.getColor(`--main-color-chart-${this.colorCount}${this.highlight}`),
				data: rkiData,
				...config,
			});
			this.checkColorCount();

			twitterData.forEach((set) => {
				if (set.type == 'frequency') {
					datasets.push({
						label: this.$t(set.name),
						borderColor: this.$global.getColor(`--main-color-chart-${this.colorCount}${this.highlight}`),
						lineBackgroundColor: this.$global.getColor(`--main-color-chart-${this.colorCount}${this.highlight}`),
						data: set.data,
						...config,
					});
				} else if (set.type == 'classifier') {
					datasets.push({
						label: `${this.$t(set.name)} ${this.$t('prInfected')}`,
						borderColor: this.$global.getColor(`--main-color-chart-${this.colorCount}`),
						lineBackgroundColor: this.$global.getColor(`--main-color-chart-${this.colorCount}`),
						data: set.infected,
						...config,
					});
					datasets.push({
						label: `${this.$t(set.name)} ${this.$t('prNotInfected')}`,
						borderColor: this.$global.getColor(`--main-color-chart-${this.colorCount}-highlight`),
						lineBackgroundColor: this.$global.getColor(`--main-color-chart-${this.colorCount}-highlight`),
						data: set.notInfected,
						...config,
					});
				}
				this.checkColorCount();
			});

			gtData.forEach((set) => {
				datasets.push({
					label: set.name,
					borderColor: this.$global.getColor(`--main-color-chart-${this.colorCount}${this.highlight}`),
					lineBackgroundColor: this.$global.getColor(`--main-color-chart-${this.colorCount}${this.highlight}`),
					data: set.data,
					...config,
				});
				this.checkColorCount();
			});

			comboData.forEach((set) => {
				let name = '';
				set.name.forEach((part, idx, arr) => {
					if (idx == arr.length - 1) name += `${this.$t(part)}`;
					else name += `${this.$t(part)}-`;
				});
				datasets.push({
					label: name,
					borderColor: this.$global.getColor(`--main-color-chart-${this.colorCount}${this.highlight}`),
					lineBackgroundColor: this.$global.getColor(`--main-color-chart-${this.colorCount}${this.highlight}`),
					data: set.data.map((d) => {
						return d.value;
					}),
					...config,
				});
				this.checkColorCount();
			});

			return datasets;
		},
		// @vuese
		// Normalizes a aray with given values
		// @arg data[Array] - Array with the data that is normalized
		// @return [Array] - Returns the normalized data
		normalizeArrays(data) {
			let datasets = [];
			data.forEach((set) => {
				let max = Math.max(...set.data);
				set.data = set.data.map(function (x) {
					return Math.round(((100 * x) / max) * 100) / 100;
				});
				datasets.push(data);
			});

			return data;
		},
		// @vuese
		// Corrects the start and end for a given dateStart and dateEnd
		// @return [Array] - Contains the start and end
		getDates() {
			let start, end;
			let min = new Date('2019-12-29');
			let max = new Date();
			if (max >= Date.parse('2023-02-13T00:00:00')) max = Date.parse('2023-02-13');

			if (this.dataStart !== '') {
				start = new Date(this.dataStart);
				if (start.getTime() < min.getTime()) {
					start = min;
					this.dataStart = min.toISOString().substring(0, 10);
				} else if (start.getTime() > max.getTime()) {
					start = max;
					this.dataStart = max.toISOString().substring(0, 10);
				}
			} else {
				start = min;
				this.dataStart = min.toISOString().substring(0, 10);
			}

			if (this.dataEnd !== '') {
				end = new Date(this.dataEnd);
				if (end.getTime() > max.getTime()) {
					end = max;
					this.dataEnd = max.toISOString().substring(0, 10);
				} else if (end.getTime() < min.getTime()) {
					end = min;
					this.dataEnd = min.toISOString().substring(0, 10);
				}
			} else {
				end = max;
				this.dataEnd = max.toISOString().substring(0, 10);
			}

			if (start.getTime() > end.getTime()) {
				start = end;
				this.dataStart = this.dataEnd;
			}
			if (end.getTime() < start.getTime()) {
				end = start;
				this.dataEnd = this.dataStart;
			}

			return [start, end];
		},
		// @vuese
		// Exports the canvas as a png
		// @arg type[String] - Can be chart or map
		downloadCanvas(type) {
			let wrapper = document.getElementById(`${type}Wrapper`);
			let canvas = wrapper.querySelector('canvas');
			let image = canvas.toDataURL();

			let element = document.createElement('a');
			element.download = `${type}_${this.$global.parseDate(new Date())}.png`;
			element.href = image;
			element.click();
			element.remove();
		},
		// @vuese
		// Changes the date range of the chart
		changeDataRange() {
			let start, end;
			[start, end] = this.getDates();

			let daylist = this.$global.getDaysArray(start, end);
			let computedRKI = this.createRKIData(daylist);
			let computedTwitter = this.createTwitterData(daylist);
			let computedGT = this.createGTData(daylist);
			let computedCombo = this.createComboData();
			let datasets = this.generateDatasets(computedRKI, computedTwitter, computedGT, computedCombo);

			let config = {
				type: 'line',
				data: {
					labels: daylist.map((day) => {
						return this.$global.parseDate(day);
					}),
					datasets: datasets,
				},
				options: {
					maintainAspectRatio: false,
					responsive: true,
				},
			};

			this.chartConfig = config;
			this.colorCount = 0;
			this.highlight = '';
		},
		// @vuese
		// Checks the description for a given config
		// @arg config[Object] - The given config
		// @return [String] - The description that was generated
		getConfigDescription(config) {
			let description = '';
			if (config.dataType == 'rki') {
				description += 'RKI ';
				if (config.mapType == 'rtds') description += `${this.$t('coTrendDailyState')}`;
				else if (config.mapType == 'rtss') description += `${this.$t('coTrendSummedState')}`;
			} else if (config.dataType == 'twitter') {
				description += 'Twitter ';
				if (config.chartType == 'frequency') description += `${this.$t('coTweetFrequency')} `;
				else if (config.chartType == 'classifier') description += `${config.classifier.classifier} `;

				if (config.mapType == 'ttds') description += `${this.$t('coTrendDailyState')}`;
				else if (config.mapType == 'ttdc') description += `${this.$t('coTrendDailyCity')}`;
				else if (config.mapType == 'ttss') description += `${this.$t('coTrendSummedState')}`;
				else if (config.mapType == 'ttsc') description += `${this.$t('coTrendSummedCity')}`;
			}

			return description;
		},
		// @vuese
		// Sets the new config fr the map
		// @arg e[Object] - The event that occured
		// @arg config[Object] - The selected config
		setMapConfig(e, config) {
			[...document.getElementsByClassName('pr-map-configs')[0].children].forEach((child) => {
				child.classList.remove('pr-conf-selected');
			});

			e.target.classList.add('pr-conf-selected');
			this.mapConfig = config;
		},
		// @vuese
		// Checks the color count of the chart and resets it if necessary
		checkColorCount() {
			if (this.colorCount + 1 == 10) {
				this.colorCount = 0;
				if (this.highlight === '') this.highlight = '-highlight';
				else this.highlight = '';
			} else this.colorCount += 1;
		},
	},
};
</script>

<style scoped>
.pr-wrap-content {
	text-align: center;
	padding: 30px 20px;
}

.pr-wrap-config {
	width: 100%;
	padding: 0px 10px;
	box-sizing: border-box;
}

.pr-wrap-info {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	text-align: center;
}

.pr-wrap-info h3 {
	margin-top: 20px;
	font-size: 30px;
	font-weight: normal;
	color: var(--main-color-1);
}

.pr-wrap-info i {
	font-size: 20px;
	color: var(--main-color-1);
}

.pr-wrap-info i:hover {
	cursor: pointer;
	color: var(--main-color-1-transparent);
}

.pr-wrap-visualizations {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin-top: 20px;
}

.pr-wrap-chart {
	max-width: calc(90vw);
	/* max-height: calc(100vh - 300px); */
	margin: auto;
	text-align: center;
	position: relative;
}

.pr-wrap-header h3 {
	display: inline-block;
}

.pr-wrap-header i {
	margin-left: 20px;
	font-size: 30px;
	color: var(--main-color-1);
}

.pr-wrap-header i:hover {
	cursor: pointer;
	color: var(--main-color-1-transparent);
}

.pr-wrap-timeframe {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	text-align: center;
}

.pr-wrap-input {
	display: inline-block;
	margin: 10px;
	color: var(--main-color-1);
}

.pr-wrap-input input {
	width: 120px;
	padding: 10px 10px;
	font-size: 15px;
	background-color: var(--main-color-4);
	color: var(--main-color-1);
	text-align: center;
}

::-webkit-calendar-picker-indicator {
	margin-left: 0px;
}

.pr-wrap-input label {
	display: block;
}

.pr-wrap-countdown {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	display: inline-block;
	margin: 5px;
	padding: 5px;
	border-radius: 10px;
	-webkit-box-shadow: 0px 0px 30px 0px black;
	box-shadow: 0px 0px 30px 0px black;
	background-color: var(--main-color-5);
	color: var(--main-color-1);
}

.pr-map-configs {
	width: 100%;
	text-align: center;
}

.pr-map-config {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin: 2px 5px;
	padding: 3px;
	display: inline-block;
	vertical-align: top;
	font-size: 20px;
	color: var(--main-color-1);
	border-bottom: 10px solid var(--main-color-border-dark);
	opacity: 0.5;
	filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
}

.pr-map-config:hover {
	opacity: 1;
	filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
	cursor: pointer;
}

.pr-conf-selected {
	opacity: 1;
	filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
	border-bottom: 10px solid var(--main-color-2);
}

.pr-wrap-map {
	margin-top: 10px;
}

@media (pointer: none), (pointer: coarse) {
	.pr-wrap-chart {
		max-width: 100% !important;
		margin: 0px;
	}
	.pr-wrap-header i {
		margin-left: 10px;
		font-size: 20px;
		color: var(--main-color-1);
	}

	.pr-wrap-input input {
		width: 100px;
		padding: 5px;
		font-size: 12px;
		background-color: var(--main-color-4);
		color: var(--main-color-1);
		text-align: center;
	}
}
</style>
