<template>
	<div class="ap-wrap-content">
		<Header />
		<div class="ap-scroll-view">
			<div id="Home" class="ap-spacer"><a name="Home"></a></div>
			<transition name="fade" mode="out-in">
				<router-view @showInfo="showInfo"> </router-view>
			</transition>
			<Footer />
		</div>
		<div v-show="infoText != ''" id="infoText" class="ap-info">
			<h4>{{ $t(infoTitle) }}</h4>
			<p v-html="$t(infoText)"></p>
			<i class="fas fa-times" @click="closeInfo"></i>
		</div>
		<div v-if="showEOSPopup" class="ap-eos-popup-wrapper">
			<div class="ap-eos-popup">
				<h2>{{ $t('apEOSHeader') }}</h2>
				<p>
					{{ $t('apEOSText1') }} (<a href="https://twitter.com/TwitterDev/status/1623467615539859456" target="_blank">{{
						$t('apEOSLink')
					}}</a
					>).<br />
					{{ $t('apEOSText2') }}
				</p>
				<i class="fas fa-times" @click="showEOSPopup = false"></i>
			</div>
		</div>
	</div>
</template>

<script>
import Header from './components/layouts/Header.vue';
import Footer from './components/layouts/Footer.vue';
import axios from 'axios';
import bowser from 'bowser';

/**
 * @group App
 * Wraps all components inside itself and displays the information text
 */
export default {
	name: 'App',
	components: {
		Header,
		Footer,
	},
	data() {
		return {
			infoText: '',
			infoTitle: '',
			showEOSPopup: false,
		};
	},
	created() {
		window.addEventListener('resize', this.resizeListener);
		axios
			.get('https://ipapi.co/json/')
			.then((res) => {
				this.$global.postData('log', '/pageAccess', { ip: res.data.ip }, null);
			})
			.catch((err) => {
				this.$global.postData('log', '/pageAccess', { ip: 'Not available' }, null);
			});
	},
	mounted() {
		if (!sessionStorage.getItem('endOfServicePopup') && new Date() > Date.parse('2023-02-13T00:00:00')) {
			sessionStorage.setItem('endOfServicePopup', 'true');
			this.showEOSPopup = true;
		}
		this.checkBrowser();
		console.log(this.$global.isMobile());
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeListener);
	},
	methods: {
		// @vuese
		// Resize event to adjust layout and parts of the component
		resizeListener() {
			if (this.infoText !== '') {
				let info = document.getElementById('infoText');
				info.style.height = 'fit-content';
				this.$nextTick(() => {
					let height = info.offsetHeight;
					window.setTimeout(function () {
						info.style.height = height + 'px';
					}, 100);
				});
			}
		},
		checkBrowser() {
			let result = bowser.getParser(window.navigator.userAgent);
			if (result.parsedResult.browser.name !== 'Chrome') {
				this.showInfo({ title: 'apWarnBrowser', text: 'apWarnBrowserInfo' });
			}
		},
		// @vuese
		// Shows the information field at the bottom of the page
		// @arg content[Object] - The content that gets displayed inside the information div with title and text
		showInfo(content) {
			this.infoTitle = content.title;
			this.infoText = content.text;
			let info = document.getElementById('infoText');
			info.style.height = 'fit-content';
			this.$nextTick(() => {
				let height = info.offsetHeight;
				info.style.height = '0px';
				window.setTimeout(function () {
					info.style.height = height + 'px';
				}, 100);
			});
		},
		// @vuese
		// Hides the information field
		closeInfo() {
			document.getElementById('infoText').style.height = null;
			let that = this;
			window.setTimeout(function () {
				that.infoTitle = '';
				that.infoText = '';
			}, 1000);
		},
	},
};
</script>

<style scoped>
.ap-wrap-content {
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: var(--main-color-3);
	position: relative;
}

.ap-scroll-view {
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: var(--main-color-3);
	scroll-behavior: smooth !important;
}

.ap-spacer {
	height: 100px;
}

.ap-info {
	width: calc(100% - 17px);
	height: 0px;
	max-height: 50vh;
	position: absolute;
	left: 0px;
	bottom: 0px;
	background-color: var(--main-color-5-transparent);
	border-top: 1px solid var(--main-color-5);
	color: var(--main-color-1);
	-webkit-transition: height 1s ease-out;
	-moz-transition: height 1s ease-out;
	transition: height 1s ease-out;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 2;
}

.ap-info p {
	padding: 10px;
	font-size: 18px;
}

.ap-info h4 {
	margin: 10px 0px 0px 10px;
	font-size: 25px;
	text-decoration: underline;
	color: var(--main-color-1);
}

.ap-info i {
	font-size: 25px;
	position: absolute;
	top: 4px;
	right: 5px;
	color: var(--secondary-color-error);
}

.ap-info i:hover {
	cursor: pointer;
	color: var(--main-color-error);
}

.ap-eos-popup-wrapper {
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #00000080;
	box-sizing: border-box;
	z-index: 1;
}

.ap-eos-popup {
	max-width: 90vw;
	max-height: 70vh;
	min-width: 50vw;
	padding: 10px 20px;
	position: relative;
	overflow: auto;
	box-sizing: border-box;
	border: 1px solid var(--main-color-1);
	border-radius: 20px;
	text-align: start;
	background-color: var(--main-color-5);
	-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s; /* Firefox < 16 */
	-ms-animation: fadein 1s; /* Internet Explorer */
	-o-animation: fadein 1s; /* Opera < 12.1 */
	animation: fadein 1s;
}

.ap-eos-popup h2 {
	margin-bottom: 20px;
	font-size: 45px;
	color: var(--main-color-2);
}

.ap-eos-popup p {
	font-size: 25px;
	color: var(--main-color-text-light);
}

.ap-eos-popup a {
	font-size: 25px;
	color: var(--main-color-2);
}

.ap-eos-popup a:hover {
	color: var(--main-color-2-transparent);
}

.ap-eos-popup i {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 25px;
	color: var(--main-color-error);
}

.ap-eos-popup i:hover {
	color: var(--secondary-color-error);
	cursor: pointer;
}

@media screen and (max-width: 500px) {
	.ap-eos-popup h2 {
		margin-bottom: 10px;
		font-size: 30px;
	}

	.ap-eos-popup p {
		font-size: 20px;
	}
}

@media screen and (max-width: 300px) {
	.ap-eos-popup h2 {
		margin-bottom: 10px;
		font-size: 25px;
	}

	.ap-eos-popup p {
		font-size: 15px;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
</style>

<style>
* {
	margin: 0;
	padding: 0;
}

*:not(i) {
	font-family: 'Balsamiq Sans', cursive !important;
	/* font-family: 'Calibri' !important; */
}

/* Global color styles */
:root {
	/* --main-color-0: #ffffff;
	--main-color-1: #edf5e1;
	--main-color-1-transparent: #edf5e1aa;
	--main-color-2: #8ee4af;
	--main-color-2-transparent: #8ee4afaa;
	--main-color-3: #5cdb95;
	--main-color-3-transparent: #5cdb95aa;
	--main-color-4: #379683;
	--main-color-4-transparent: #379683aa;
	--main-color-5: #05396b;
	--main-color-5-transparent: #05396baa; */

	--main-color-0: #ffffff;
	--main-color-1: #efd6ac;
	--main-color-1-transparent: #efd6acaa;
	--main-color-2: #d46322;
	--main-color-2-transparent: #d46322aa;
	--main-color-3: #432534;
	--main-color-3-transparent: #432534aa;
	--main-color-4: #183a37;
	--main-color-4-transparent: #183a37aa;
	--main-color-5: #1b2b35;
	--main-color-5-transparent: #1b2b35aa;

	--main-color-text-light: #ffffff;
	--main-color-text-dark: #000000;
	--main-color-border-light: #ffffff;
	--main-color-border-dark: #000000;
	--main-color-selected: #e2bf20;

	--main-color-success: #00bb09;
	--secondary-color-success: #00bb09aa;
	--main-color-warning: #f17c0d;
	--secondary-color-warning: #f17c0daa;
	--main-color-error: #fd0000;
	--secondary-color-error: #fd0000aa;
	--main-color-disabled: #aaaaaa;

	--main-color-twitter: #00a5bb;
	--main-color-gt: #ff7300;
	--main-color-combo: #bbb829;
	--main-color-rki: #01860c;

	--main-clock-bg-light: var(--main-color-3);
	--main-clock-bg-dark: var(--main-color-4);
	--main-clock-text-light: var(--main-color-1);
	--main-clock-text-dark: var(--main-color-1-transparent);

	--main-color-chart-0: #ff8c00;
	--main-color-chart-0-highlight: #834800;
	--main-color-chart-1: #00aaff;
	--main-color-chart-1-highlight: #005783;
	--main-color-chart-2: #65b904;
	--main-color-chart-2-highlight: #478500;
	--main-color-chart-3: #ff0000;
	--main-color-chart-3-highlight: #880000;
	--main-color-chart-4: #ff00ff;
	--main-color-chart-4-highlight: #830083;
	--main-color-chart-5: #4c5eeb;
	--main-color-chart-5-highlight: #333e94;
	--main-color-chart-6: #a1b413;
	--main-color-chart-6-highlight: #707e06;
	--main-color-chart-7: #02c282;
	--main-color-chart-7-highlight: #02865a;
	--main-color-chart-8: #7300ff;
	--main-color-chart-8-highlight: #38007c;
	--main-color-chart-9: #ff6600;
	--main-color-chart-9-highlight: #853500;
}

/* Global scrollbar style */
::-webkit-scrollbar {
	width: 17px;
	box-sizing: border-box;
}

/* Global scrollbar track style */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px black;
	/* border-radius: 10px; */
	background-color: var(--main-color-4);
}

/* Global scrollbar thumb style */
::-webkit-scrollbar-thumb {
	background: var(--main-color-2);
	/* border-radius: 10px; */
	border: 1px solid black;
}

/* Global scrollbar corner style */
::-webkit-scrollbar-corner {
	background-color: var(--main-color-2);
}

/* Global button style */
button {
	outline: none !important;
	box-shadow: none !important;
	background-color: var(--main-color-4);
	border: 2px solid var(--main-color-border-dark);
	border-radius: 10px;
	padding: 5px;
	color: var(--main-color-text-light);
}

/* Global button hover style */
button:hover {
	cursor: pointer;
	background-color: var(--main-color-2) !important;
	color: var(--main-color-text-dark);
}

/* Global button disabled style */
button:disabled {
	background-color: var(--main-color-disabled);
	pointer-events: none;
}

/* Global button disabled hover style */
button:disabled:hover {
	background-color: var(--main-color-disabled) !important;
}

/* Global input/textarea/select style */
input,
textarea,
select {
	padding: 5px 10px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 10px;
	color: var(--main-color-text-dark);
	background-color: var(--main-color-2);
}

/* Global input/textarea/select disabled style */
input[disabled],
textarea[disabled],
select:disabled {
	color: var(--main-color-text-dark);
	background-color: var(--main-color-2);
	border: 1px solid var(--main-color-border-dark);
}

/* Global input/textarea/select focus style */
input:focus,
textarea:focus,
select:focus {
	outline: none;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: 0 0 0 2pt var(--main-color-5);
}

/* Global textarea style */
textarea {
	resize: none;
}

/* Global select style */
.select {
	min-height: 40px;
}

/* Global option style */
option {
	padding: 5px;
	font-size: 17px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

/* Heading style */
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--main-color-1);
	cursor: default;
}

h1,
h2,
h3 {
	text-decoration: underline;
}

h2 {
	font-size: 60px;
}

h3 {
	font-size: 40px;
}

.swal2-container h2 {
	text-decoration: none;
}

@media screen and (max-width: 500px) {
	h1 {
		font-size: 50px;
	}

	h2 {
		font-size: 50px;
	}

	h3 {
		font-size: 30px;
	}
}

@media screen and (max-width: 300px) {
	h1 {
		font-size: 40px;
	}

	h2 {
		font-size: 30px;
	}

	h3 {
		font-size: 20px;
	}
}

@media (pointer: none), (pointer: coarse) {
	::-webkit-scrollbar {
		width: 12px;
		box-sizing: border-box;
	}
}
</style>
