import Vue from 'vue';
import VueRouter from 'vue-router';
import Site from '../views/Site.vue';
import LegalNotice from '../views/LegalNotice.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Site',
		component: Site,
	},
	{
		path: '/LegalNotice',
		name: 'LegalNotice',
		component: LegalNotice,
	},
	{
		path: '*',
		name: 'NotFound',
		component: NotFound,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
