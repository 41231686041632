<template>
	<div class="si-wrap-content">
		<Home />
		<Background />
		<Prediction
			:statisticsReady="statisticsReady"
			@setConfigs="setConfigs"
			@correlationData="setCorrelationData"
			@showInfo="$emit('showInfo', $event)"
		/>
		<Statistics :configs="configs" :correlationData="correlationData" @statisticsLoaded="statisticsReady = !statisticsReady" />
		<div id="scrollUp" class="si-scroll-up">
			<i class="fas fa-arrow-up" @click="scrollToTop"></i>
			<p class="si-up-text" @click="scrollToTop">{{ $t('siBackUp') }}</p>
		</div>
	</div>
</template>

<script>
import Home from './Home.vue';
import Background from './Background.vue';
import Prediction from './Prediction.vue';
import Statistics from './Statistics.vue';
/**
 * @group App
 * Provides the layout for all main views
 */
export default {
	name: 'Site',
	components: {
		Home,
		Background,
		Prediction,
		Statistics,
	},
	emits: ['showInfo'],
	data() {
		return {
			anchors: [],
			configs: null,
			correlationData: null,
			statisticsReady: false,
		};
	},
	created() {
		window.addEventListener('load', this.setLocationHash);
	},

	beforeDestroy() {
		window.removeEventListener('load', this.setLocationHash);
	},
	methods: {
		// @vuese
		// Sets the configs for the statistics view
		// @arg configs[Array] - All currently configured configs
		setConfigs(configs) {
			this.configs = null;
			this.$nextTick(() => {
				this.configs = configs;
			});
		},
		// @vuese
		// Sets the correlated data for the statistics view
		// @arg correlationData[Array] - The current correlation data
		setCorrelationData(correlationData) {
			this.correlationData = correlationData;
		},
		scrollToTop() {
			document.querySelector('#Home').scrollIntoView({ behavior: 'smooth' });
		},
	},
};
</script>

<style scoped>
.si-wrap-content {
	position: relative;
}

.si-scroll-up {
	display: block;
	position: fixed;
	bottom: -10px;
	right: 20px;
	text-align: end;
	padding: 10px;
}
.si-scroll-up i {
	color: var(--main-color-text-light);
	font-size: 30px;
}
.si-scroll-up i:hover {
	cursor: pointer;
	color: var(--main-color-2);
}

.si-up-text {
	color: var(--main-color-text-light);
	font-size: 15px !important;
	width: 30px;
	cursor: pointer;
}

@media (pointer: none), (pointer: coarse) {
	.si-scroll-up {
		right: 10px;
	}
	.si-scroll-up i {
		font-size: 20px;
	}

	.si-up-text {
		font-size: 10px !important;
		width: 20px;
	}
}
</style>
