<template>
	<div class="cd-wrap-content">
		<h4 v-if="type == 'twitter'">Twitter</h4>
		<h4 v-if="type == 'gt'">Google Trends</h4>
		<h4 v-if="type == 'rki'">Robert Koch-Institut</h4>
		<FlipCountdown v-if="nextDeadline" :deadline="nextDeadline" :showDays="showDays" :showHours="showHours" />
	</div>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown';
/**
 * @group Prediction
 * Shows the time until the next data querys occure
 */
export default {
	name: 'Countdown',
	components: {
		FlipCountdown,
	},
	props: {
		// Type of the countdown
		type: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			nextDeadline: null,
			showDays: false,
			showHours: true,
		};
	},
	created() {
		let now = new Date();
		if (this.type == 'twitter') this.showHours = false;

		if (this.type == 'rki') {
			if (now.getHours() < 6) {
				let nd = new Date();
				nd.setHours(6, 0, 0);
				let date = nd.getFullYear() + '-' + ('0' + (nd.getMonth() + 1)).slice(-2) + '-' + ('0' + nd.getDate()).slice(-2);
				this.nextDeadline = `${date} ${nd.toLocaleTimeString()}`;
			}
		}

		if (this.type == 'gt') {
			if (now.getHours() < 20) {
				let nd = new Date();
				nd.setHours(20, 0, 0);
				let date = nd.getFullYear() + '-' + ('0' + (nd.getMonth() + 1)).slice(-2) + '-' + ('0' + nd.getDate()).slice(-2);
				this.nextDeadline = `${date} ${nd.toLocaleTimeString()}`;
			}
		}

		window.setInterval(() => {
			if (new Date(this.nextDeadline) - new Date() <= 0) this.nextDeadline = this.computeNextDeadline();
		}, 1000);
	},
	methods: {
		// @vuese
		// Computes the next deadline depending on the given type
		// @return [String] - The new deadline
		computeNextDeadline() {
			let nd = new Date();
			if (this.type == 'twitter') {
				let remainder = 15 - (nd.getMinutes() % 15);
				nd = new Date(nd.getTime() + remainder * 60000);
				nd.setSeconds(0);
			} else if (this.type == 'gt') {
				nd.setHours(20, 0, 0);
				nd.setDate(new Date().getDate() + 1);
			} else if (this.type == 'rki') {
				nd.setHours(6, 0, 0);
				nd.setDate(new Date().getDate() + 1);
			}
			let date = nd.getFullYear() + '-' + ('0' + (nd.getMonth() + 1)).slice(-2) + '-' + ('0' + nd.getDate()).slice(-2);
			return `${date} ${nd.toLocaleTimeString()}`;
		},
	},
};
</script>

<style>
.cd-wrap-content h4 {
	font-size: 20px;
	color: var(--main-color-1);
}

/* Upper tile if not collapsing */
.flip-card__top {
	color: var(--main-clock-text-dark) !important;
	background-color: var(--main-clock-bg-dark) !important;
	border: 1px solid black;
	border-bottom: none;
}

/* Lower plate if not collapsing */
.flip-card__bottom {
	color: var(--main-clock-text-light) !important;
	background-color: var(--main-clock-bg-light) !important;
	border: 1px solid black;
	border-top: none;
}

/* Background of the lower plate before top one reaches it */
.flip-card__back-bottom {
	color: var(--main-clock-text-light) !important;
	background-color: var(--main-clock-bg-light) !important;
	border: 1px solid black;
	border-top: none;
}

/* Is the top tile collapsing down */
.flip-card__back::before {
	color: var(--main-clock-text-dark) !important;
	background-color: var(--main-clock-bg-dark) !important;
	border: 1px solid black;
	border-bottom: none;
}
</style>
