<template>
	<div class="sl-wrap-content">
		<div v-if="generalConfigs" class="sl-wrap-configs">
			<Statistic v-for="config in generalConfigs" :key="config.type" :config="config">
				<p v-if="['aqt', 'utu', 'uta', 'vtw', 'ltw', 'stw', 'lpt', 'spt', 'tuc', 'tul'].includes(config.type)">
					{{ config.value }}
					<span v-if="['utu'].includes(config.type)">{{ $t('slUser') }}</span>
					<span v-if="['ltw', 'stw', 'lpt', 'spt'].includes(config.type)">{{ $t('slChars') }}</span>
					<span v-if="['tuc'].includes(config.type)">{{ $t('slCities') }}</span>
					<span v-if="['aqt', 'uta', 'vtw', 'tul'].includes(config.type)">Tweets</span>
				</p>
				<p v-if="['twg', 'twc'].includes(config.type)">
					{{ config.value }} <span>Tweets</span> ({{
						Math.round((config.value / generalConfigs.find((conf) => conf.type == 'aqt').value) * 10000) / 100
					}}
					%)
				</p>
				<p v-if="['pwl', 'pwd'].includes(config.type)">
					{{ config.value }} ({{
						Math.round((config.value / generalConfigs.find((conf) => conf.type == 'utu').value) * 10000) / 100
					}}
					%)
				</p>
				<div v-if="config.type == 'ttu'" class="sl-wrap-elements">
					<div v-for="(user, idx) in config.value" :key="user.id">
						<p>{{ $t('slUser') }} {{ (idx + 10).toString(36).toUpperCase() }}</p>
						<p>{{ user.tweets }} Tweets</p>
					</div>
				</div>
			</Statistic>
		</div>
		<div v-else-if="chartConfigs">
			<div v-for="stack in chartConfigs" :key="stack.type">
				<h4 v-if="stack.type == 'frequency'" class="sl-divider-header">
					{{ $t('coTweetFrequency') }}
					<i class="fas fa-window-minimize" @click="minimizeStats($event, stack.id)"></i>
					<i class="fas fa-window-maximize sl-hidden" @click="maximizeStats($event, stack.id)"></i>
				</h4>
				<h4 v-else class="sl-divider-header">
					{{ $t(`${stack.type}`) }}
					<i class="fas fa-window-minimize" @click="minimizeStats($event, stack.id)"></i>
					<i class="fas fa-window-maximize sl-hidden" @click="maximizeStats($event, stack.id)"></i>
				</h4>
				<div :id="stack.id" class="sl-wrap-configs">
					<Statistic v-for="config in stack.statistic" :key="getKey(config)" :config="config">
						<p v-if="!['cor', 'cpv', 'gtl', 'gth', 'gta', 'col', 'coh', 'coa'].includes(config.type)">
							{{ config.value }}
							<span v-if="!['cra'].includes(config.type)">Tweets</span>
						</p>
						<p v-else-if="config.type == 'cor'">{{ Math.round(config.value * 1000) / 1000 }}</p>
						<p v-else-if="config.type == 'cpv'">{{ trimPValue(config.value) }}</p>
						<p v-else>{{ config.value }}</p>
					</Statistic>
				</div>
			</div>
		</div>
		<div v-else-if="mapConfigs">
			<div v-for="stack in mapConfigs" :key="stack.type">
				<h4 v-if="stack.type == 'frequency'" class="sl-divider-header">
					{{ $t('coTweetFrequency') }}
					<i class="fas fa-window-minimize" @click="minimizeStats($event, stack.id)"></i>
					<i class="fas fa-window-maximize sl-hidden" @click="maximizeStats($event, stack.id)"></i>
				</h4>
				<h4 v-else class="sl-divider-header">
					{{ $t(`${stack.type}`) }}
					<i class="fas fa-window-minimize" @click="minimizeStats($event, stack.id)"></i>
					<i class="fas fa-window-maximize sl-hidden" @click="maximizeStats($event, stack.id)"></i>
				</h4>
				<div :id="stack.id" class="sl-wrap-configs">
					<Statistic v-for="config in stack.statistic" :key="getKey(config)" :config="config">
						<!-- prettier-ignore -->
						<p v-if="['flc', 'fhc', 'fac', 'fls', 'fhs', 'fas', 'clc', 'chc', 'cac', 'cls', 'chs', 'cas'].includes(config.type)">
							{{ config.value }}
							<span>Tweets</span>
						</p>
						<div v-if="['ftc', 'fts', 'ctc', 'cts', 'rts'].includes(config.type)" class="sl-wrap-elements">
							<div v-for="element in config.value" :key="element.id">
								<p v-if="element.city">{{ element.city }}</p>
								<p v-if="element.state">{{ element.state }}</p>
								<p v-if="['rts'].includes(config.type)">{{ element.cases }} {{ $t('slInfections') }}</p>
								<p v-if="['ftc', 'fts', 'ctc', 'cts'].includes(config.type)">{{ element.tweets }} Tweets</p>
							</div>
						</div>
					</Statistic>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Statistic from './Statistic.vue';
/**
 * @group Statistics
 * The list of all statistics
 */
export default {
	name: 'StatisticsList',
	components: {
		Statistic,
	},
	props: {
		// The general configs for twitter
		generalConfigs: {
			type: Array,
			required: false,
		},
		// The configs for the line chart
		chartConfigs: {
			type: Array,
			required: false,
		},
		// The configs for the map
		mapConfigs: {
			type: Array,
			required: false,
		},
	},
	created() {
		window.addEventListener('resize', this.resizeListener);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeListener);
	},
	methods: {
		// @vuese
		// Generates a key for a given config
		// @arg config[Object] - The given config
		// @return [String] - The generated key
		getKey(config) {
			if (config.extra) {
				if (config.extra.classifier) return `${config.type}-${config.extra.classifier}`;
			} else {
				return config.type;
			}
		},
		// @vuese
		// Trims the p value to be exacly 5 digits after the point
		// @arg value[Number] - The p value
		// @return [Number] - The trimmed value
		trimPValue(value) {
			let parsed = '';
			let parts = value.toString().split('e');
			if (parts.length > 1) {
				parsed = `${Math.round(Number(parts[0]) * 10000) / 10000}e${parts[1]}`;
			} else {
				parsed = Math.round(Number(parts[0]) * 10000) / 10000;
			}
			return parsed;
		},
		// @vuese
		// Minimizes the stats or a whole list of stats
		// @arg e[Object] - The event that occured
		// @arg id[String] - The ID of the collapsed statitics
		minimizeStats(e, id) {
			let stats = document.getElementById(id);
			stats.style.height = stats.offsetHeight + 1 + 'px';
			window.setTimeout(function () {
				stats.style.height = '0px';
				stats.style.overflow = 'hidden';

				e.target.classList.add('sl-hidden');
				e.target.nextSibling.classList.remove('sl-hidden');
			}, 100);
		},
		// @vuese
		// Maximizes the stats or a whole list of stats
		// @arg e[Object] - The event that occured
		// @arg id[String] - The ID of the maximized statitics
		maximizeStats(e, id) {
			let stats = document.getElementById(id);
			stats.style.height = 'fit-content';
			this.$nextTick(() => {
				let height = stats.offsetHeight;
				stats.style.height = '0px';
				window.setTimeout(function () {
					stats.style.height = height + 'px';
					window.setTimeout(() => {
						stats.style.overflow = null;
						stats.style.height = 'fit-content';
					}, 500);
				}, 100);
			});

			e.target.classList.add('sl-hidden');
			e.target.previousSibling.classList.remove('sl-hidden');
		},
	},
};
</script>

<style scoped>
.sl-wrap-content {
	-webkit-transition: height 1s ease-in-out;
	-moz-transition: height 1s ease-in-out;
	transition: height 1s ease-in-out;
}

.sl-wrap-content h4 {
	color: var(--main-color-1);
	font-size: 24px;
}

.sl-wrap-content h4 i {
	font-size: 20px;
}
.sl-wrap-configs {
	display: flex;
	flex-flow: row wrap;
	margin: 10px 0px;
	-webkit-transition: height 0.5s ease-in-out;
	-moz-transition: height 0.5s ease-in-out;
	transition: height 0.5s ease-in-out;
	/* overflow: auto; */
}

.sl-wrap-elements {
	/* width: 100%; */
	display: flex;
	flex-flow: row wrap;
}

.sl-wrap-elements div {
	/* display: inline-block; */
	flex-grow: 1;
	flex-direction: row;
	min-width: 100px;
	padding: 0px 5px;
	margin: 2px 0px;
	border-right: 1px solid var(--main-color-border-dark);
}
.sl-wrap-elements div:last-child {
	border-right: 1px solid var(--main-color-2);
}

.sl-wrap-elements div p:first-child {
	font-weight: bold;
}

.sl-divider-header {
	margin: 20px 0px 0px 0px;
	padding: 0px 30px;
	font-size: 20px;
	position: relative;
}

.sl-divider-header i {
	position: absolute;
	top: -5px;
	right: 10px;
	color: var(--main-color-1-transparent);
}

.sl-divider-header i:hover {
	cursor: pointer;
	color: var(--main-color-1);
}

.sl-hidden {
	display: none;
}
</style>
