<template>
	<div class="co-wrap-content">
		<div class="co-best-config">
			<h3 class="co-small-header">{{ $t('coBestPrediction') }}</h3>
			<button class="co-button" @click="useBestPrediction">{{ $t('coUseBestPrediction') }}</button>
		</div>
		<div class="co-wrap-buttons">
			<h3 class="co-small-header">{{ $t('coOwnConfiguration') }}</h3>
			<div class="co-top-buttons">
				<h4>
					<!-- {{ $t('coVisualizationType') }} -->
					{{ $t('coPredictionType') }}
					<i
						class="fas fa-info-circle"
						@click="$emit('showInfo', { title: 'coVisualizationType', text: 'coVisTypeInfo' })"
					></i>
				</h4>
				<!-- <button class="co-button" @click="setVisualization($event, 'chart')">{{ $t('coLineChart') }}</button> -->
				<button class="co-button" @click="setVisualization($event, 'chart')">{{ $t('coTime') }}</button>
				<!-- <button class="co-button" @click="setVisualization($event, 'map')">{{ $t('coMap') }}</button> -->
				<button class="co-button" @click="setVisualization($event, 'map')">{{ $t('coAreal') }}</button>
			</div>
			<div v-if="visualizationType == 'chart'" class="co-mid-buttons">
				<h4>
					{{ $t('coDataType') }}
					<i class="fas fa-info-circle" @click="$emit('showInfo', { title: 'coDataType', text: 'coDataTypeInfo' })"></i>
				</h4>
				<button class="co-button" @click="setDataType($event, 'twitter')">Twitter</button>
				<button class="co-button" @click="setDataType($event, 'gt')">Google Trends</button>
				<button class="co-button" @click="setDataType($event, 'combo')">
					{{ $t('coCombined') }}
				</button>
			</div>
			<div v-else-if="visualizationType == 'map'" class="co-mid-buttons">
				<h4>
					{{ $t('coDataType') }}
					<i class="fas fa-info-circle" @click="$emit('showInfo', { title: 'coDataType', text: 'coDataTypeInfo' })"></i>
				</h4>
				<button class="co-button" @click="setDataType($event, 'twitter')">Twitter</button>
				<button class="co-button" @click="setDataType($event, 'rki')">RKI</button>
			</div>
		</div>
		<div v-if="dataType" class="co-wrap-selections">
			<div v-if="visualizationType == 'chart'" class="co-bot-buttons">
				<h4>
					{{ $t('coChartType') }}
					<i class="fas fa-info-circle" @click="$emit('showInfo', { title: 'coChartType', text: 'coChartTypeInfo' })"></i>
				</h4>
				<button v-if="dataType == 'twitter'" class="co-button" @click="setChartType($event, 'classifier')">
					{{ $t('coTweetClassifier') }}
				</button>
				<button v-if="dataType == 'twitter'" class="co-button" @click="setChartType($event, 'frequency')">
					{{ $t('coTweetFrequency') }}
				</button>
				<button v-if="dataType == 'gt'" class="co-button" @click="setChartType($event, 'single')">
					{{ $t('coGTSingle') }}
				</button>
				<button v-if="dataType == 'gt'" class="co-button" @click="setChartType($event, 'multi')">
					{{ $t('coGTMulti') }}
				</button>
				<div v-if="dataType == 'combo'" class="co-combo">
					<div class="co-combo-left">
						<h4>{{ $t('coTweetTypes') }}</h4>
						<button class="co-button" @click="setMultiChartType($event, 'classifier')">
							{{ $t('coTweetClassifier') }}
						</button>
						<button class="co-button" @click="setMultiChartType($event, 'frequency')">
							{{ $t('coTweetFrequency') }}
						</button>
					</div>
					<div class="co-combo-right">
						<h4>{{ $t('coGTTypes') }}</h4>
						<button class="co-button" @click="setMultiChartType($event, 'single')">
							{{ $t('coGTSingle') }}
						</button>
						<button class="co-button" @click="setMultiChartType($event, 'multi')">
							{{ $t('coGTMulti') }}
						</button>
					</div>
				</div>
			</div>
			<div v-else-if="visualizationType == 'map'">
				<div class="co-map-buttons">
					<h4>
						{{ $t('coMapType') }}
						<i class="fas fa-info-circle" @click="$emit('showInfo', { title: 'coMapType', text: 'coMapTypeInfo' })"></i>
					</h4>
					<button v-if="dataType == 'twitter'" class="co-button" @click="setMapType($event, 'ttds')">
						{{ $t('coTrendDailyState') }}
					</button>
					<button v-if="dataType == 'twitter'" class="co-button" @click="setMapType($event, 'ttdc')">
						{{ $t('coTrendDailyCity') }}
					</button>
					<button v-if="dataType == 'twitter'" class="co-button" @click="setMapType($event, 'ttss')">
						{{ $t('coTrendSummedState') }}
					</button>
					<button v-if="dataType == 'twitter'" class="co-button" @click="setMapType($event, 'ttsc')">
						{{ $t('coTrendSummedCity') }}
					</button>
					<button v-if="dataType == 'rki'" class="co-button" @click="setMapType($event, 'rtds')">
						{{ $t('coTrendDailyState') }}
					</button>
					<button v-if="dataType == 'rki'" class="co-button" @click="setMapType($event, 'rtss')">
						{{ $t('coTrendSummedState') }}
					</button>
				</div>
				<div v-if="mapType && dataType == 'twitter'" class="co-bot-buttons">
					<h4>
						{{ $t('coChartType') }}
						<i
							class="fas fa-info-circle"
							@click="$emit('showInfo', { title: 'coChartType', text: 'coChartTypeInfo' })"
						></i>
					</h4>
					<button class="co-button" @click="setChartType($event, 'classifier')">
						{{ $t('coTweetClassifier') }}
					</button>
					<button class="co-button" @click="setChartType($event, 'frequency')">
						{{ $t('coTweetFrequency') }}
					</button>
				</div>
			</div>
		</div>
		<div v-if="chartType">
			<div v-if="dataType == 'twitter' && chartType !== 'frequency'" class="co-wrap-elements">
				<ClassifierList :classifiers="classifiers" @setClassifier="setClassifier" @showInfo="$emit('showInfo', $event)" />
			</div>
			<div v-if="dataType == 'gt'" class="co-wrap-elements">
				<KeywordList :keywords="keywords" @setKeyword="setKeyword" @showInfo="$emit('showInfo', $event)" />
			</div>
			<div v-if="dataType == 'combo'" class="co-wrap-elements">
				<div>
					<div v-if="chartType.split('-')[0] !== 'frequency'" class="co-wrap-lists">
						<ClassifierList
							:classifiers="classifiers"
							@setClassifier="setClassifier"
							@showInfo="$emit('showInfo', $event)"
						/>
					</div>
					<div :class="chartType.split('-')[0] !== 'frequency' ? 'co-wrap-lists' : 'co-wrap-list'">
						<KeywordList :keywords="keywords" @setKeyword="setKeyword" @showInfo="$emit('showInfo', $event)" />
					</div>
				</div>
			</div>
		</div>
		<button
			v-if="(visualizationType && dataType && chartType) || (visualizationType && dataType == 'rki' && mapType)"
			class="co-add-config"
			:disabled="addDisabled"
			@click="addConfig"
		>
			{{ $t('coAddConfig') }}
		</button>
		<div class="co-selected-configs">
			<ConfigElement
				v-for="element in selectedConfigs"
				:key="element.id"
				:config="element"
				:queryFailed="checkFail(element)"
				@removeConfig="removeConfig"
			/>
		</div>
		<div>
			<div class="co-wrap-reset">
				<div v-if="showDeleteControl" class="co-remove-config">
					<p>{{ $t('ceDelete') }}?</p>
					<button @click="resetConfig" class="co-yes">{{ $t('ceYes') }}</button>
					<button @click="showDeleteControl = false" class="co-no">{{ $t('ceNo') }}</button>
				</div>
				<p v-if="selectedConfigs.length > 0" class="co-reset-config" @click="showDeleteControl = !showDeleteControl">
					{{ $t('coResetConfig') }}
				</p>
			</div>
			<button class="co-query-data" :disabled="selectedConfigs.length == 0 || queryDisabled" @click="queryData">
				{{ $t('coQueryData') }}
			</button>
			<div v-if="isLoading" class="co-loading-button vld-parent" ref="loadingContainer">
				<Loading
					:active.sync="isLoading"
					:is-full-page="false"
					:loader="'dots'"
					:width="60"
					:height="40"
					:color="$global.getColor('--main-color-2')"
					:background-color="$global.getColor('--main-color-3')"
					:opacity="0"
					:container="$refs.loadingContainer"
					:zIndex="1000"
				></Loading>
			</div>
		</div>
	</div>
</template>

<script>
import ClassifierList from './ClassifierList';
import KeywordList from './KeywordList';
import ConfigElement from './ConfigElement';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
/**
 * @group Prediction
 * The config generation for the data visualization
 */
export default {
	name: 'Config',
	components: {
		ClassifierList,
		KeywordList,
		ConfigElement,
		Loading,
	},
	props: {
		// All available classifiers
		classifiers: {
			type: Array,
			required: true,
		},
		// All available keywords
		keywords: {
			type: Array,
			required: true,
		},
		// Is true while data is queried
		queryDisabled: {
			type: Boolean,
			required: true,
		},
		// Toggles the loading screen
		loadingToggle: {
			type: Boolean,
			required: true,
		},
		// Checks if the query failed
		failedQuerys: {
			type: Array,
			required: true,
		},
	},
	watch: {
		loadingToggle: {
			handler: function () {
				this.isLoading = false;
			},
		},
	},
	emits: ['showInfo'],
	data() {
		return {
			isLoading: false,
			visualizationType: null,
			dataType: null,
			chartType: null,
			mapType: null,
			comboTweetType: null,
			comboGTType: null,
			selectedClassifiers: [],
			selectedKeywords: [],
			selectedConfigs: [],
			addDisabled: true,
			showDeleteControl: false,
		};
	},
	methods: {
		useBestPrediction() {
			console.log('Use');
			this.selectedConfigs = [
				{
					id: this.$uuid.v4(),
					visType: 'chart',
					dataType: 'twitter',
					mapType: null,
					chartType: 'classifier',
					selectedClassifiers: [
						{ classifier: 'SVM', description: 'clSVMDescription', name: 'clSVM' },
						{ classifier: 'RNN', description: 'clRNNDescription', name: 'clRNN' },
					],
					selectedKeywords: [],
				},
				{
					id: this.$uuid.v4(),
					visType: 'chart',
					dataType: 'gt',
					mapType: null,
					chartType: 'multi',
					selectedClassifiers: [],
					selectedKeywords: ['PCR', 'Quarantäne'],
				},
			];
			this.queryData();
		},
		// @vuese
		// Resets the vars depending on the level of the configuration
		// @arg lvl[Number] - Determines which values will be reset
		resetVars(lvl) {
			if (lvl >= 3) this.dataType = null;
			if (lvl >= 2) this.chartType = null;
			if (lvl >= 2) this.selectedClassifiers = [];
			if (lvl >= 2) this.selectedKeywords = [];
			if (lvl >= 2) this.comboTweetType = null;
			if (lvl >= 2) this.comboGTType = null;
			if (lvl >= 2) this.addDisabled = true;
			if (lvl >= 1) this.mapType = null;
		},
		// @vuese
		// Resets the colors of a config button
		// @arg target[Object] - The targeted DOM element
		// @arg className[String] - The class name of the other elements that get reset
		resetColor(target, className) {
			[...document.getElementsByClassName(className)[0].children].forEach((btn) => {
				btn.classList.remove('co-clicked');
			});
			target.classList.add('co-clicked');
		},
		// @vuese
		// Sets the visualization type
		// @arg e[Object] - The event that occured
		// @arg type[String] - The type that gets set
		setVisualization(e, type) {
			this.resetVars(3);
			this.visualizationType = type;

			this.$nextTick(() => {
				[...document.getElementsByClassName('co-button')].forEach((btn) => {
					btn.classList.remove('co-clicked');
				});
				e.target.classList.add('co-clicked');
			});
		},
		// @vuese
		// Sets the data type
		// @arg e[Object] - The event that occured
		// @arg type[String] - The type that gets set
		setDataType(e, type) {
			this.resetVars(2);
			this.dataType = type;
			this.$nextTick(() => {
				this.resetColor(e.target, 'co-mid-buttons');
				if (this.visualizationType == 'chart') {
					this.resetColor(e.target, 'co-bot-buttons');
				} else if (this.visualizationType == 'map') this.resetColor(e.target, 'co-map-buttons');
			});
		},
		// @vuese
		// Sets the map type
		// @arg e[Object] - The event that occured
		// @arg type[String] - The type that gets set
		setMapType(e, type) {
			this.resetVars(2);
			this.mapType = type;
			if (this.dataType == 'rki') this.chartType = '';
			this.$nextTick(() => {
				this.resetColor(e.target, 'co-map-buttons');
				if (this.dataType != 'rki') this.resetColor(e.target, 'co-bot-buttons');
			});
			this.checkQuery();
		},
		// @vuese
		// Sets the chart type
		// @arg e[Object] - The event that occured
		// @arg type[String] - The type that gets set
		setChartType(e, type) {
			this.chartType = type;

			if (type == 'frequency') this.selectedClassifiers = [];

			this.$nextTick(() => {
				this.resetColor(e.target, 'co-bot-buttons');
			});
			this.checkQuery();
		},
		// @vuese
		// Sets the type for multiple charts
		// @arg e[Object] - The event that occured
		// @arg type[String] - The type that gets set
		setMultiChartType(e, type) {
			this.resetVars(1);
			if (type == 'frequency' || type == 'classifier') {
				this.comboTweetType = type;
				if (type == 'frequency') this.selectedClassifiers = [];

				this.$nextTick(() => {
					this.resetColor(e.target, 'co-combo-left');
				});
			}
			if (type == 'single' || type == 'multi') {
				this.comboGTType = type;

				this.$nextTick(() => {
					this.resetColor(e.target, 'co-combo-right');
				});
			}

			if (this.comboTweetType && this.comboGTType) this.chartType = `${this.comboTweetType}-${this.comboGTType}`;
			this.checkQuery();
		},
		// @vuese
		// Sets a classifier
		// @arg e[Object] - The event that occured
		// @arg classifier[Object] - The selected classifier
		setClassifier(e, classifier) {
			let target = e.target.tagName !== 'P' ? e.target : e.target.parentElement;
			if (e.target.tagName !== 'I') {
				if (target.classList.contains('cl-clicked')) {
					target.classList.remove('cl-clicked');
					document.getElementById('all-classifiers').classList.remove('cl-clicked');
					if (classifier == 'all') {
						this.selectedClassifiers = [];
						[...document.getElementsByClassName('cl-class-list-element')].forEach((elem) => {
							elem.classList.remove('cl-clicked');
						});
					} else {
						this.selectedClassifiers = this.selectedClassifiers.filter((cf) => cf !== classifier);
					}
				} else {
					target.classList.add('cl-clicked');
					if (classifier == 'all') {
						this.selectedClassifiers = this.classifiers;
						[...document.getElementsByClassName('cl-class-list-element')].forEach((elem) => {
							elem.classList.add('cl-clicked');
						});
					} else {
						this.selectedClassifiers.push(classifier);
						if (this.selectedClassifiers.length == this.classifiers.length) {
							document.getElementById('all-classifiers').classList.add('cl-clicked');
						}
					}
				}
			}

			this.checkQuery();
		},
		// @vuese
		// Sets a keyword
		// @arg e[Object] - The event that occured
		// @arg keyword[Object] - The selected keyword
		setKeyword(e, keyword) {
			if (e.target.classList.contains('kl-clicked')) {
				e.target.classList.remove('kl-clicked');
				document.getElementById('all-keywords').classList.remove('kl-clicked');
				if (keyword == 'all') {
					this.selectedKeywords = [];
					[...document.getElementsByClassName('kl-keyword-list-element')].forEach((elem) => {
						elem.classList.remove('kl-clicked');
					});
				} else {
					this.selectedKeywords = this.selectedKeywords.filter((key) => key !== keyword);
				}
			} else {
				e.target.classList.add('kl-clicked');
				if (keyword == 'all') {
					this.selectedKeywords = this.keywords;
					[...document.getElementsByClassName('kl-keyword-list-element')].forEach((elem) => {
						elem.classList.add('kl-clicked');
					});
				} else {
					this.selectedKeywords.push(keyword);
					if (this.selectedKeywords.length == this.keywords.length) {
						document.getElementById('all-keywords').classList.add('kl-clicked');
					}
				}
			}

			this.checkQuery();
		},
		// @vuese
		// Checks if the query can be enabled
		checkQuery() {
			if (this.dataType == 'combo') {
				if (!this.chartType) this.addDisabled = true;
				else if (
					(this.selectedClassifiers.length == 0 && !this.chartType.includes('frequency')) ||
					this.selectedKeywords.length == 0
				)
					this.addDisabled = true;
				else this.addDisabled = false;
			} else if (this.dataType == 'twitter') {
				if (this.selectedClassifiers.length == 0 && this.chartType !== 'frequency') this.addDisabled = true;
				else this.addDisabled = false;
			} else if (this.dataType == 'gt') {
				if (this.selectedKeywords.length == 0) this.addDisabled = true;
				else this.addDisabled = false;
			} else if (this.dataType == 'rki') {
				if (!this.mapType) this.addDisabled = true;
				else this.addDisabled = false;
			}
		},
		// @vuese
		// Adds a generated config
		addConfig() {
			this.selectedConfigs.push({
				id: this.$uuid.v4(),
				visType: this.visualizationType,
				dataType: this.dataType,
				mapType: this.mapType,
				chartType: this.chartType,
				selectedClassifiers: this.selectedClassifiers,
				selectedKeywords: this.selectedKeywords,
			});
			console.log(this.selectedConfigs[this.selectedConfigs.length - 1]);

			this.resetVars(3);

			this.$nextTick(() => {
				[...document.getElementsByClassName('co-mid-buttons')[0].children].forEach((btn) => {
					btn.classList.remove('co-clicked');
				});
			});
		},
		// @vuese
		// Removes a config
		// @arg id[Number] - The ID of the removed config
		removeConfig(id) {
			this.selectedConfigs = this.selectedConfigs.filter((config) => config.id !== id);
		},
		// @vuese
		// Sort function for a array of classifiers
		// @return [NUmber] - Order for the current element
		sortClassifiers(a, b) {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		},
		// @vuese
		// Checks if the query for a config failed
		// @arg config[Object] - The checked config
		// @return [Boolean] - Is true if the query failed
		checkFail(config) {
			if (this.failedQuerys.includes(config.id)) return true;
			else return false;
		},
		// @vuese
		// Resets all configs
		resetConfig() {
			this.showDeleteControl = false;
			this.selectedConfigs = [];
		},
		// @vuese
		// Querys data for all generated configs
		queryData() {
			// TODO:
			// Combine all querys and check for more duplication (e.g. 2x single keywords)
			let parsedConfigs = [];
			this.selectedConfigs.forEach((config) => {
				// Filters out the same configs
				let exists = parsedConfigs.filter(
					(conf) =>
						conf.dataType == config.dataType &&
						conf.chartType == config.chartType &&
						conf.mapType == config.mapType &&
						JSON.stringify(conf.selectedKeywords.sort()) == JSON.stringify(config.selectedKeywords.sort()) &&
						JSON.stringify(conf.selectedClassifiers.sort(this.sortClassifiers)) ==
							JSON.stringify(config.selectedClassifiers.sort(this.sortClassifiers))
				)[0];
				if (!exists) parsedConfigs.push(config);
			});
			this.selectedConfigs = parsedConfigs;

			this.isLoading = true;
			this.$emit('queryData', this.selectedConfigs);
		},
	},
};
</script>

<style scoped>
.co-wrap-content {
	width: 100%;
}

.co-small-header {
	margin-top: 20px;
	font-size: 30px;
	font-weight: normal;
	text-decoration: none;
}

.co-wrap-content h4 {
	margin-top: 10px;
	font-size: 20px;
	font-weight: normal;
}

/* .co-wrap-content h4 {
	font-size: 17px;
} */

.co-wrap-content i {
	font-size: 20px;
}

.co-wrap-content i:hover {
	cursor: pointer;
	color: var(--main-color-1-transparent);
}

.co-wrap-buttons {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.co-button {
	font-size: 20px;
	margin: 5px;
}

.co-clicked {
	background-color: var(--main-color-2) !important;
	color: var(--main-color-text-dark) !important;
}

.co-combo {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
}

.co-combo-left,
.co-combo-right {
	width: calc(50% - 20px);
	display: inline-block;
	vertical-align: top;
}

.co-wrap-selections {
	width: 100%;
}

.co-wrap-elements {
	margin-top: 10px;
	font-size: 30px;
}

.co-wrap-lists {
	width: 50%;
	max-height: 500px;
	display: inline-block;
	overflow-y: auto;
	overflow-x: hidden;
	vertical-align: top;
}

.co-wrap-list {
	width: 100%;
	max-height: 500px;
	display: inline-block;
	overflow: auto;
}

.co-selected-configs {
	margin: 10px;
}

.co-add-config {
	width: 200px;
	height: 35px;
	margin-top: 20px;
	font-size: 17px;
	line-height: 25px;
}

.co-wrap-reset {
	width: 270px;
	margin: 20px auto 0px auto;
	position: relative;
	text-align: center;
}

.co-remove-config {
	position: absolute;
	right: 70px;
	top: -70px;
	z-index: 3;
	padding: 5px;
	border-radius: 10px;
	background-color: var(--main-color-1-transparent);
	-webkit-box-shadow: 0 0px 15px 0px var(--main-color-border-dark);
	box-shadow: 0 0px 15px 0px var(--main-color-border-dark);
}

.co-yes,
.co-no {
	width: 50px;
	height: 30px;
	margin: 2px;
	padding: 2px;
}

.co-yes {
	background-color: var(--main-color-error);
}

.co-yes:hover {
	background-color: var(--secondary-color-error) !important;
}

.co-no {
	background-color: var(--main-color-4);
}

.co-no:hover {
	background-color: var(--main-color-4-transparent) !important;
}

.co-reset-config {
	font-size: 17px;
	font-style: italic;
	color: var(--main-color-1);
}

.co-reset-config:hover {
	cursor: pointer;
	text-decoration: underline;
}

.co-query-data {
	width: 300px;
	height: 35px;
	margin-top: 10px;
	font-size: 20px;
	line-height: 25px;
}

.co-loading-button {
	width: 100%;
	height: 70px;
	position: relative;
}

@media (pointer: none), (pointer: coarse) {
	.co-button {
		font-size: 12px;
		margin: 5px;
	}

	.co-combo .co-button {
		min-height: 45px;
	}

	.co-combo-left h4,
	.co-combo-right h4 {
		font-size: 15px;
	}

	.co-reset-config {
		font-size: 12px;
		font-style: italic;
		color: var(--main-color-1);
	}
}
</style>
