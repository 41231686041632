<template>
	<div class="nf-wrap-content">
		<div class="nf-wrap-logo">
			<img :src="require(`@/assets/Logo_700x691.png`)" :alt="$t('heLogo')" />
		</div>
		<div class="nf-wrap-msg">
			<h1>404</h1>
			<h2>{{ $t('nfNotFound') }}</h2>
		</div>
	</div>
</template>

<script>
/**
 * @group Error
 * Catches every route that is not covered by the server and displays a error message
 */
export default {
	name: 'NotFound',
};
</script>

<style scoped>
.nf-wrap-content {
	width: 100%;
	height: fit-content;
	min-height: 650px;
}

.nf-wrap-logo {
	width: 600px;
	height: 600px;
	margin: 50px;
	float: left;
}

.nf-wrap-logo img {
	width: 100%;
	height: 100%;
}

.nf-wrap-msg {
	width: calc(100vw - 800px);
	display: inline-block;
}

.nf-wrap-msg h1 {
	font-size: 150px;
}

.nf-wrap-msg h2 {
	font-size: 50px;
	text-decoration: none;
}

@media screen and (max-width: 1300px) {
	/* .nf-wrap-logo {
		width: 400px;
		height: 400px;
		margin: 25px;
		display: inline-block;
	} */

	.nf-wrap-msg {
		width: calc(100vw - 800px);
		display: inline-block;
	}

	.nf-wrap-msg h1 {
		font-size: 100px;
	}

	.nf-wrap-msg h2 {
		font-size: 40px;
		text-decoration: none;
	}
}

@media screen and (max-width: 1030px) {
	.nf-wrap-logo {
		width: 500px;
		height: 500px;
		margin: 10px;
		display: inline-block;
	}

	.nf-wrap-msg {
		width: calc(100vw - 600px);
		margin-left: 50px;
		display: inline-block;
	}

	.nf-wrap-msg h1 {
		font-size: 80px;
	}

	.nf-wrap-msg h2 {
		font-size: 30px;
	}
}

@media screen and (max-width: 780px) {
	.nf-wrap-logo {
		width: 100%;
		height: 400px;
		margin: 10px 0px;
		display: inline-block;
		text-align: center;
	}

	.nf-wrap-logo img {
		width: 400px;
		height: 400px;
	}

	.nf-wrap-msg {
		width: calc(100vw - 100px);
		margin-left: 50px;
		display: inline-block;
	}

	.nf-wrap-msg h1 {
		font-size: 80px;
	}

	.nf-wrap-msg h2 {
		font-size: 30px;
	}
}

@media screen and (max-width: 450px) {
	.nf-wrap-logo {
		width: 100%;
		height: 300px;
		margin: 10px 0px;
		display: inline-block;
		text-align: center;
	}

	.nf-wrap-logo img {
		width: 300px;
		height: 300px;
	}
}

@media screen and (max-width: 350px) {
	.nf-wrap-logo {
		width: 100%;
		height: 200px;
		margin: 10px 0px;
		display: inline-block;
		text-align: center;
	}

	.nf-wrap-logo img {
		width: 200px;
		height: 200px;
	}
}
</style>
