<template>
	<div class="st-wrap-content">
		<h4>
			{{ $t(`st${config.type.toUpperCase()}`) }}
		</h4>
		<slot>
			<p>{{ $t('stNoContent') }}</p>
		</slot>
	</div>
</template>

<script>
/**
 * @group Statistics
 * The list of all statistics
 */
export default {
	name: 'Statistic',
	props: {
		// The config of the statistic
		config: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style scoped>
.st-wrap-content {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	min-width: 100px;
	max-height: fit-content;
	max-height: -webkit-fit-content;
	margin: 5px;
	padding: 5px;
	border: 1px solid var(--main-color-border-dark);
	border-top: none;
	border-left: none;
	background-color: var(--main-color-2);
	-webkit-box-shadow: 5px 5px 20px 5px var(--main-color-border-dark);
	box-shadow: 5px 5px 20px 5px var(--main-color-border-dark);
	vertical-align: top;
}

.st-wrap-content h4 {
	font-size: 20px;
	/* max-width: 300px; */
	margin: auto;
	color: var(--main-color-5);
}

.st-wrap-content p {
	margin-top: 5px;
	font-size: 17px;
	color: var(--main-color-5);
}

@media (pointer: none), (pointer: coarse) {
	.st-wrap-content h4 {
		font-size: 15px;
		/* max-width: 300px; */
		margin: auto;
		color: var(--main-color-5);
	}
	.st-wrap-content p {
		margin-top: 5px;
		font-size: 12px;
		color: var(--main-color-5);
	}
}
</style>
