<template>
	<div class="ma-wrap-content">
		<div v-if="showTimeConfig" class="ma-wrap-time-config">
			<div class="ma-timer-element">
				<select class="ma-date-select" @change="setStart" v-model="startDay" :disabled="isPlaying">
					<option value="none" disabled selected hidden>{{ $t('maSelectStart') }}</option>
					<option v-for="day in getDayList()" :key="day" :value="day">{{ day }}</option>
				</select>
				<p>{{ $t('maBeginning') }}</p>
			</div>
			<div v-if="!isPlaying" class="ma-timer-element">
				<i class="fas fa-play" @click="startInterval"></i>
				<p>{{ $t('maStart') }}</p>
			</div>
			<div v-if="isPlaying" class="ma-timer-element">
				<i class="fas fa-pause" @click="stopInterval"></i>
				<p>{{ $t('maStop') }}</p>
			</div>
			<div class="ma-timer-element">
				<i class="fas fa-undo" @click="resetInterval"></i>
				<p>{{ $t('maReset') }}</p>
			</div>
			<div class="ma-timer-element">
				<select v-model="intervalSpeed" @change="setNewInterval">
					<option value="1">1x</option>
					<option value="2">2x</option>
					<option value="4">4x</option>
					<option value="8">8x</option>
				</select>
				<p>{{ $t('maSpeed') }}</p>
			</div>
			<p>{{ currentTs }}</p>
		</div>
		<div v-show="toggleChart" id="mapWrapper" class="ma-wrap-map" @contextmenu="resetChart">
			<canvas id="map"></canvas>
		</div>
	</div>
</template>

<script>
import { topojson } from 'chartjs-chart-geo';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
Chart.register(...registerables);
/**
 * @group Prediction
 * The map that geographically displays the data of twitter or the rki
 */
export default {
	name: 'Map',
	props: {
		// The config of the visualization
		config: {
			type: Object,
			required: true,
		},
	},
	watch: {
		config: {
			handler: function () {
				this.isPlaying = false;
				clearInterval(this.interval);
				this.interval = null;
				this.currentState = 0;
				this.toggleChart = false;
				this.startDay = 'none';
				this.chart.destroy();
				this.createMap();
			},
		},
	},
	data() {
		return {
			toggleChart: false,
			germany: null,
			states: null,
			chartCtx: null,
			chart: null,
			startDay: 'none',
			swapWidth: null,
			showTimeConfig: false,
			isPlaying: false,
			interval: null,
			intervalSpeed: 1,
			currentTs: new Date('2019-12-29'),
			currentState: 0,
			currentData: [],
		};
	},
	created() {
		let that = this;
		this.$global.getData('data', '/mapData', null, function (err, result) {
			if (err) that.$global.showToast('error', `${err.response.status}: ${that.$t(err.response.data.msg)}`);
			else {
				that.states = topojson
					.feature(result.germanyStates, result.germanyStates.objects.layer)
					.features.filter((item) => item.properties.NAME_0 === 'Germany');
				const countries = topojson.feature(result.germany, result.germany.objects.continent_Europe_subunits).features;
				that.germany = countries.find((d) => d.properties.geounit === 'Germany');
				that.createMap();
			}
		});

		window.addEventListener('resize', this.resizeListener);
	},
	beforeDestroy() {
		this.chart.destroy();
		window.removeEventListener('resize', this.resizeListener);
	},
	methods: {
		// @vuese
		// The resize listener of the component
		resizeListener() {
			let wrapper = document.getElementsByClassName('ma-wrap-map')[0];
			if (!this.swapWidth) {
				this.swapWidth = window.innerHeight * 0.9 * 1.98;
			}
			if (wrapper.clientHeight >= window.innerHeight * 0.9 || wrapper.clientHeight == 0) {
				if (window.innerWidth <= this.swapWidth) this.chart.options.maintainAspectRatio = true;
				else this.chart.options.maintainAspectRatio = false;
			} else this.chart.options.maintainAspectRatio = true;
		},
		// @vuese
		// Creates the map depending on the config
		createMap() {
			try {
				let responsive = false;
				let maintainAspectRatio = false;
				let legendDisplay = true;
				let padding = 20;
				if (this.$global.isMobile()) {
					responsive = true;
					legendDisplay = false;
					padding = 0;
				}
				this.showTimeConfig = true;
				this.chartCtx = document.getElementById('map').getContext('2d');
				// Trend of the states
				if (['ttds', 'ttss', 'rtds', 'rtss'].includes(this.config.mapType)) {
					this.chart = new Chart(this.chartCtx, {
						type: 'choropleth',
						data: {
							labels: [],
							datasets: [
								{
									label: this.$global.parseDate(new Date('2019-12-29')),
									outline: this.germany,
									borderColor: '#000000',
									borderWidth: 2,
									data: [],
								},
							],
						},
						options: {
							maintainAspectRatio: maintainAspectRatio,
							responsive: responsive,
							showOutline: false,
							showGraticule: false,
							layout: {
								padding: {
									right: padding,
								},
							},
							plugins: {
								legend: {
									display: false,
								},
							},
							scales: {
								xy: {
									projection: 'equalEarth',
									padding: padding,
								},
								color: {
									type: 'colorLogarithmic',
									display: legendDisplay,
								},
							},
						},
					});
				}
				// Trend of the citys
				else if (['ttdc', 'ttsc'].includes(this.config.mapType)) {
					this.chart = new Chart(this.chartCtx, {
						type: 'bubbleMap',
						data: {
							labels: [],
							datasets: [
								{
									label: this.$global.parseDate(new Date('2019-12-29')),
									outline: this.states,
									borderColor: '#000000',
									outlineBackgroundColor: '#ffffff',
									outlineBorderColor: '#000000',
									outlineBorderWidth: 2,
									borderWidth: 2,
									backgroundColor: '#05396b',
									data: [],
								},
							],
						},
						options: {
							maintainAspectRatio: maintainAspectRatio,
							responsive: responsive,
							showOutline: true,
							outline: {
								backgroundColor: '#ffffff',
								borderColor: '#000000',
								borderWidth: 2,
							},
							showGraticule: false,
							layout: {
								padding: {
									bottom: 30,
								},
							},
							animation: {
								duration: 0,
							},
							plugins: {
								legend: {
									display: false,
									title: {
										color: '#ff0000',
									},
								},
								datalabels: {
									align: 'top',
									formatter: (v) => {
										return v.city;
									},
								},
							},
							scales: {
								xy: {
									projection: 'equalEarth',
									padding: 0,
								},
								r: {
									display: legendDisplay,
									size: [1, 20],
								},
								size: {
									display: legendDisplay,
								},
							},
						},
					});
				}

				this.getCurrentValues();
				window.setTimeout(() => {
					window.dispatchEvent(new Event('resize'));
				}, 50);
			} catch (error) {
				console.log(error);
				this.$global.showToast('error', this.$t('maMapLoadFailed'));
			}
		},
		// @vuese
		// Resets the chart interval
		// @arg e[Object] - The event that occured
		resetChart(e) {
			e.preventDefault();
			this.resetInterval();
		},
		// @vuese
		// Generates a list of dates between two dates
		// @return [Array] - Array with all generated dates
		getDayList() {
			let arr = [];
			let start = new Date('2019-12-29');
			let end = new Date();
			if (this.config.dataType == 'rki') end.setDate(end.getDate() - 1);
			for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
				arr.push(this.$global.parseDate(dt));
			}
			return arr;
		},
		// @vuese
		// Sets the start of the interval
		// @arg e[Object] - The event that occured
		setStart(e) {
			let lastState = this.currentState;
			this.currentState = e.target.selectedIndex - 1;
			let currentTs = null;
			let chartData = [];
			if (['ttds', 'rtds', 'ttss', 'rtss'].includes(this.config.mapType)) {
				for (const state in this.config.data) {
					if (!currentTs) currentTs = new Date(this.config.data[state].data[this.currentState].timestamp);

					if (['ttds', 'rtds'].includes(this.config.mapType)) {
						chartData.push({
							feature: this.states.find((s) => s.properties.NAME_1 == state),
							value: this.config.data[state].data[this.currentState].value,
						});
					} else if (['ttss', 'rtss'].includes(this.config.mapType)) {
						chartData.push({
							feature: this.states.find((s) => s.properties.NAME_1 == state),
							value: this.config.data[state].data
								.slice(0, this.currentState)
								.map((d) => d.value)
								.reduce((a, b) => a + b, 0),
						});
					}

					this.chart.data.labels = chartData.map((d) => d.feature.properties.NAME_1);
					this.chart.data.datasets[0].data = chartData;
					this.currentTs = this.$global.parseDate(currentTs);

					this.chart.update();
				}
			} else if (['ttdc', 'ttsc']) {
				let labels = [];
				let values = [];
				currentTs = new Date(this.config.data[this.currentState].timestamp);
				if (['ttdc'].includes(this.config.mapType) || this.currentState == 0) {
					let data = this.config.data[this.currentState];
					labels = data.values.map((d) => d.city);
					values = data.values.map((d) => {
						return {
							city: d.city,
							value: d.value,
							latitude: Number(d.latitude),
							longitude: Number(d.longitude),
						};
					});
					this.chart.data.labels = labels;
					this.chart.data.datasets[0].data = values;
					this.currentTs = this.$global.parseDate(currentTs);
					this.chart.update();
				} else if (['ttsc'].includes(this.config.mapType)) {
					let data;
					let reverse = false;
					if (lastState < this.currentState) data = this.config.data.slice(lastState, this.currentState);
					else {
						data = this.config.data.slice(this.currentState, lastState);
						reverse = true;
					}
					data.forEach((da) => {
						let labels = da.values.map((d) => d.city);
						let values = da.values.map((d) => {
							return {
								city: d.city,
								value: d.value,
								latitude: Number(d.latitude),
								longitude: Number(d.longitude),
							};
						});
						if (!reverse) {
							labels.forEach((label) => {
								if (!this.chart.data.labels.includes(label)) this.chart.data.labels.push(label);
							});
						}

						values.forEach((v) => {
							let idx = this.chart.data.datasets[0].data.findIndex((d) => d.city == v.city);
							if (idx > -1)
								if (reverse) {
									this.chart.data.datasets[0].data[idx].value -= v.value;
									if (this.chart.data.datasets[0].data[idx].value == 0) {
										this.chart.data.datasets[0].data.splice(idx, 1);
									}
								} else this.chart.data.datasets[0].data[idx].value += v.value;
							else this.chart.data.datasets[0].data.push(v);
						});
					});

					this.currentTs = this.$global.parseDate(currentTs);

					this.chart.update();
				}
			}
		},
		// @vuese
		// Starts the interval
		startInterval() {
			this.isPlaying = true;
			this.interval = window.setInterval(() => {
				this.getCurrentValues();
				this.currentState += 1;
			}, 1000 / Number(this.intervalSpeed));
		},
		// @vuese
		// Gets the current values in the interval for the current timestamp
		getCurrentValues() {
			let chartData = [];
			let currentTs = null;
			// States
			if (['ttds', 'rtds', 'ttss', 'rtss'].includes(this.config.mapType)) {
				for (const state in this.config.data) {
					if (
						this.currentState >= this.config.data[state].data.length ||
						(this.currentState >= this.config.data[state].data.length - 1 && this.config.dataType == 'rki')
					) {
						this.stopInterval();
						break;
					}
					currentTs = new Date(this.config.data[state].data[this.currentState].timestamp);
					// Daily values
					if (['ttds', 'rtds'].includes(this.config.mapType) || this.currentState == 0) {
						chartData.push({
							feature: this.states.find((s) => s.properties.NAME_1 == state),
							value: this.config.data[state].data[this.currentState].value,
						});
					}
					// Summed values
					else if (['ttss', 'rtss'].includes(this.config.mapType)) {
						chartData.push({
							feature: this.states.find((s) => s.properties.NAME_1 == state),
							value:
								this.chart.data.datasets[0].data.find((d) => d.feature.properties.NAME_1 == state).value +
								this.config.data[state].data[this.currentState].value,
						});
					}
				}

				if (currentTs) {
					this.chart.data.labels = chartData.map((d) => d.feature.properties.NAME_1);
					this.chart.data.datasets[0].data = chartData;
					this.currentTs = this.$global.parseDate(currentTs);

					this.chart.update();

					if (!this.toggleChart) {
						this.toggleChart = true;
						window.setTimeout(() => {
							this.chart.options.responsive = true;
							this.resetInterval();
						}, 100);
					}
				}
			} else if (['ttdc', 'ttsc']) {
				if (this.currentState >= this.config.data.length) {
					this.stopInterval();
				} else {
					let data = this.config.data[this.currentState];
					let labels = data.values.map((d) => d.city);
					let values = data.values.map((d) => {
						return {
							city: d.city,
							value: d.value,
							latitude: Number(d.latitude),
							longitude: Number(d.longitude),
						};
					});
					currentTs = new Date(this.config.data[this.currentState].timestamp);

					// Daily values
					if (['ttdc'].includes(this.config.mapType) || this.currentState == 0) {
						this.chart.data.labels = labels;
						this.chart.data.datasets[0].data = values;
					}
					// Summed values
					else if (['ttsc'].includes(this.config.mapType)) {
						labels.forEach((label) => {
							if (!this.chart.data.labels.includes(label)) this.chart.data.labels.push(label);
						});

						values.forEach((v) => {
							let idx = this.chart.data.datasets[0].data.findIndex((d) => d.city == v.city);
							if (idx > -1) this.chart.data.datasets[0].data[idx].value += v.value;
							else this.chart.data.datasets[0].data.push(v);
						});
					}

					this.currentTs = this.$global.parseDate(currentTs);

					this.chart.update();

					if (!this.toggleChart) {
						this.toggleChart = true;
						window.setTimeout(() => {
							this.chart.options.responsive = true;
							this.resetInterval();
						}, 100);
					}
				}
			}
		},
		// @vuese
		// Sets the new interval of the map
		setNewInterval() {
			if (this.isPlaying) {
				clearInterval(this.interval);
				this.interval = window.setInterval(() => {
					this.getCurrentValues();
					this.currentState += 1;
				}, 1000 / Number(this.intervalSpeed));
			}
		},
		// @vuese
		// Stops the interval
		stopInterval() {
			this.isPlaying = false;
			clearInterval(this.interval);
			this.interval = null;
		},
		// @vuese
		// Resets the interval
		resetInterval() {
			this.isPlaying = false;
			clearInterval(this.interval);
			this.interval = null;
			this.currentState = 0;
			this.startDay = 'none';
			this.getCurrentValues();
		},
	},
};
</script>

<style scoped>
.ma-wrap-content {
	width: 100%;
	margin: 10px auto;
	padding: 5px 5px 0px 5px;
	border-radius: 10px;
	background-color: var(--main-color-1);
	-webkit-box-shadow: 0px 0px 20px 10px var(--main-color-border-dark);
	box-shadow: 0px 0px 20px 10px var(--main-color-border-dark);
}

.ma-wrap-time-config {
	width: 100%;
	margin: 10px auto;
}

.ma-timer-element {
	height: 60px;
	display: inline-block;
	vertical-align: top;
	text-align: center;
	margin: 0px 10px;
	font-size: 20px;
}

.ma-timer-element i {
	font-size: 25px;
	margin-bottom: 10px;
	vertical-align: top;
}

.ma-timer-element select {
	width: 80px;
	margin-bottom: 3px;
	background-color: var(--main-color-4);
	color: var(--main-color-text-light);
}

.ma-date-select {
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
}

.ma-timer-element select option {
	text-align: start !important;
}

.ma-timer-element i:hover {
	cursor: pointer;
	color: var(--main-color-4);
}

.ma-wrap-map {
	max-height: 90vh;
	max-width: calc(90vh * 1.98);
	margin: auto;
}

@media (pointer: none), (pointer: coarse) {
	.ma-wrap-map {
		min-height: 50vh;
		max-width: calc(90vh * 1.98);
		margin: auto;
	}
	.ma-wrap-time-config {
		width: 100%;
		margin: 5px auto;
	}

	.ma-timer-element {
		height: 40px;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		margin: 0px 5px;
		font-size: 10px;
	}

	.ma-timer-element i {
		font-size: 20px;
		margin-bottom: 10px;
		vertical-align: top;
	}

	.ma-timer-element select {
		width: 50px;
		padding-left: 2px;
		margin-bottom: 3px;
		background-color: var(--main-color-4);
		color: var(--main-color-text-light);
		font-size: 10px;
	}

	.ma-date-select {
		width: -webkit-fit-content !important;
		width: -moz-fit-content !important;
		width: fit-content !important;
		font-size: 10px;
	}

	.ma-timer-element select option {
		text-align: start !important;
		font-size: 12px;
	}

	.ma-timer-element i:hover {
		cursor: pointer;
		color: var(--main-color-4);
	}
}
</style>
