import swal from 'sweetalert2';
import axios from 'axios';
import bowser from 'bowser';

let queue = [];
let blockQueue = false;
export const global = {
	// Ends the loading screen on the current page
	// @arg type[String] - The type of the toast
	// @arg msg[String] - The displayed message
	showToast: function (type, msg) {
		if (!blockQueue) {
			blockQueue = true;
			window.setTimeout(() => {
				// Remove all similar msgs from queue
				queue = queue.filter((pair) => pair[1] != msg);
				swal.mixin({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timer: 2500,
					timerProgressBar: true,
					didOpen: (toast) => {
						toast.addEventListener('mouseenter', swal.stopTimer);
						toast.addEventListener('mouseleave', swal.resumeTimer);
						toast.addEventListener('mouseup', swal.close);
					},
				}).fire({
					icon: type,
					title: msg,
				});
				window.setTimeout(() => {
					blockQueue = false;
				}, 2500);
			}, 100);
		} else {
			queue.push([type, msg]);
			let timeout = window.setInterval(() => {
				if (!blockQueue) {
					clearTimeout(timeout);

					let toast = queue.shift();
					if (toast) this.showToast(toast[0], toast[1]);
				}
			}, 100);
		}
	},
	getData: async function (endpoint, route, data, cb) {
		axios
			.get(`/api/${endpoint}${route}`, { params: data })
			.then((res) => {
				if (cb) cb(null, res.data);
			})
			.catch((err) => {
				if (cb) cb(err, null);
			});
	},
	postData: async function (endpoint, route, data, cb) {
		axios
			.post(`/api/${endpoint}${route}`, data)
			.then((res) => {
				if (cb) cb(null, res.data);
			})
			.catch((err) => {
				if (cb) cb(err, null);
			});
	},
	remoteLogger: function (log) {
		this.postData('log', '/remoteLog', { log: log }, null);
	},
	parseDate: function (date) {
		if (!(date instanceof Date)) date = new Date(date);
		let dd = String(date.getDate()).padStart(2, '0');
		let mm = String(date.getMonth() + 1).padStart(2, '0');
		let yyyy = date.getFullYear();

		let parsed = dd + '.' + mm + '.' + yyyy;
		return parsed;
	},
	getColor: function (color) {
		return `${getComputedStyle(document.getElementsByTagName('body')[0]).getPropertyValue(color)}`.trim();
	},
	getDaysArray: function (start, end) {
		let arr = [];
		start.setHours(start.getHours() + 1);
		for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
			dt.setHours((dt.getTimezoneOffset() / 60) * -1);
			arr.push(new Date(dt));
		}
		return arr;
	},
	isMobile: function () {
		let result = bowser.getParser(window.navigator.userAgent);
		if (result.parsedResult.platform.type == 'mobile') return true;
		else return false;
	},
};
