import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en.json';
import de from './de.json';

const languages = {
	en: en,
	de: de,
};

const messages = Object.assign(languages);

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: 'de',
	fallbackLocale: 'en',
	messages,
});

export default i18n;
