<template>
	<div class="ho-wrap-content">
		<div class="ho-wrap-logo">
			<img :src="require(`@/assets/Logo_700x691.png`)" :alt="$t('heLogo')" />
		</div>
		<div class="ho-wrap-title">
			<div class="ho-wrap-header">
				<h1>{{ $t('hoTitle') }}</h1>
				<p>
					{{ $t('hoBy') }}
					<a href="https://www.linkedin.com/in/philip-storz-171700207" target="_blank"> Philip Storz</a>
				</p>
			</div>
		</div>
		<a name="Home"></a>
	</div>
</template>

<script>
/**
 * @group Home
 * Displays the logo and title of the page
 */
export default {
	name: 'Home',
};
</script>

<style scoped>
.ho-wrap-content {
	width: 100%;
	height: 700px;
	margin-bottom: 20px;
	/* -webkit-box-shadow: 0px 0px 30px 30px var(--main-color-3);
	box-shadow: 0px 0px 30px 30px var(--main-color-3); */
}

.ho-wrap-logo {
	width: 600px;
	height: 600px;
	margin: 50px;
	float: left;
}

.ho-wrap-logo img {
	width: 100%;
	height: 100%;
}

.ho-wrap-title {
	width: calc(100% - 700px);
	height: 100%;
	/* display: inline-block;
	text-align: center; */
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
}

.ho-wrap-title h1 {
	height: 100%;
	margin: 0px 20px;
	font-size: 75px;
}

.ho-wrap-title a,
.ho-wrap-title p {
	width: fit-content;
	margin: auto;
	font-size: 25px;
	color: var(--main-color-1);
}

.ho-wrap-title a {
	color: var(--main-color-2);
	font-size: inherit;
	text-decoration: none;
}

.ho-wrap-title a:hover {
	text-decoration: underline;
}

@media screen and (max-width: 1300px) {
	.ho-wrap-content {
		width: 100%;
		height: 500px;
		margin-bottom: 20px;
		/* -webkit-box-shadow: 0px 0px 30px 30px var(--main-color-3);
	box-shadow: 0px 0px 30px 30px var(--main-color-3); */
	}

	.ho-wrap-logo {
		width: 400px;
		height: 400px;
		margin: 25px;
		display: inline-block;
	}

	.ho-wrap-title {
		width: calc(100% - 450px);
		height: 100%;
	}

	.ho-wrap-title h1 {
		height: 100%;
		margin: 0px 20px;
		font-size: 50px;
	}

	.ho-wrap-title p {
		font-size: 22px;
	}
}

@media screen and (max-width: 850px) {
	.ho-wrap-content {
		width: 100%;
		height: 350px;
		margin-bottom: 20px;
		/* -webkit-box-shadow: 0px 0px 30px 30px var(--main-color-3);
	box-shadow: 0px 0px 30px 30px var(--main-color-3); */
	}

	.ho-wrap-logo {
		width: 300px;
		height: 300px;
		margin: 10px;
		display: inline-block;
	}

	.ho-wrap-title {
		width: calc(100% - 320px);
		height: 100%;
	}

	.ho-wrap-title h1 {
		height: 100%;
		margin: 0px 20px;
		font-size: 35px;
	}

	.ho-wrap-title p {
		font-size: 19px;
	}
}

@media screen and (max-width: 615px) {
	.ho-wrap-content {
		width: 100%;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		margin-bottom: 20px;
		/* -webkit-box-shadow: 0px 0px 30px 30px var(--main-color-3);
	box-shadow: 0px 0px 30px 30px var(--main-color-3); */
	}

	.ho-wrap-logo {
		width: 100%;
		height: 400px;
		margin: 10px 0px;
		display: inline-block;
		text-align: center;
	}

	.ho-wrap-logo img {
		width: 400px;
		height: 400px;
	}

	.ho-wrap-title {
		width: calc(100% - 20px);
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}

	.ho-wrap-title h1 {
		height: 100%;
		margin: 0px 20px;
		font-size: 40px;
	}

	.ho-wrap-title p {
		font-size: 17px;
	}
}

@media screen and (max-width: 450px) {
	.ho-wrap-content {
		width: 100%;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		margin-bottom: 20px;
		/* -webkit-box-shadow: 0px 0px 30px 30px var(--main-color-3);
	box-shadow: 0px 0px 30px 30px var(--main-color-3); */
	}

	.ho-wrap-logo {
		width: 100%;
		height: 300px;
		margin: 10px 0px;
		display: inline-block;
		text-align: center;
	}

	.ho-wrap-logo img {
		width: 300px;
		height: 300px;
	}

	.ho-wrap-title {
		width: calc(100% - 20px);
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}

	.ho-wrap-title h1 {
		height: 100%;
		margin: 0px 20px;
		font-size: 35px;
	}

	.ho-wrap-title p {
		font-size: 15px;
	}
}

@media screen and (max-width: 350px) {
	.ho-wrap-content {
		width: 100%;
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
		margin-bottom: 20px;
		/* -webkit-box-shadow: 0px 0px 30px 30px var(--main-color-3);
	box-shadow: 0px 0px 30px 30px var(--main-color-3); */
	}

	.ho-wrap-logo {
		width: 100%;
		height: 200px;
		margin: 10px 0px;
		display: inline-block;
		text-align: center;
	}

	.ho-wrap-logo img {
		width: 200px;
		height: 200px;
	}

	.ho-wrap-title {
		width: calc(100% - 20px);
		height: -webkit-fit-content;
		height: -moz-fit-content;
		height: fit-content;
	}

	.ho-wrap-title h1 {
		height: 100%;
		margin: 0px 20px;
		font-size: 30px;
	}

	.ho-wrap-title p {
		font-size: 15px;
	}
}
</style>
