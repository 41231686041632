<template>
	<div class="kl-wrap-content">
		<h4>
			{{ $t('klKeywords') }}
			<i class="fas fa-info-circle" @click="$emit('showInfo', { title: 'klKeywords', text: 'klKeywordsInfo' })"></i>
		</h4>
		<div class="kl-wrap-list">
			<div :id="'all-keywords'" class="kl-keyword-list-element" @click="$emit('setKeyword', $event, 'all')">
				{{ $t('klAll') }}
			</div>
			<div
				v-for="keyword in keywords"
				:key="keyword"
				v-html="keyword"
				class="kl-keyword-list-element"
				@click="$emit('setKeyword', $event, keyword)"
			></div>
		</div>
	</div>
</template>

<script>
/**
 * @group Prediction
 * A list of all available keywords
 */
export default {
	name: 'KeywordList',
	props: {
		// The available keywords
		keywords: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style scoped>
.kl-wrap-content h4 {
	margin-top: 10px;
	font-size: 20px;
	font-weight: normal;
	color: var(--main-color-1);
}

.kl-wrap-content i:hover {
	cursor: pointer;
	color: var(--main-color-1-transparent);
}

.kl-wrap-list {
	width: 90%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin: auto;
	padding: 0px 10px;
}

.kl-class-list-element,
.kl-keyword-list-element {
	width: 150px;
	height: 40px;
	margin: 5px;
	padding: 2px 5px;
	display: inline-block;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 10px;
	font-size: 17px;
	background-color: var(--main-color-4);
	color: var(--main-color-text-light);
	vertical-align: top;
	text-align: center;
	position: relative;
}

.kl-class-list-element {
	padding-right: 20px;
}

.kl-class-list-element:hover,
.kl-keyword-list-element:hover {
	cursor: pointer;
	background-color: var(--main-color-2);
	color: var(--main-color-text-dark);
}
.kl-class-list-element i,
.kl-keyword-list-element i {
	position: absolute;
	top: 2px;
	right: 2px;
}

.kl-class-list-element:hover i:hover {
	color: var(--main-color-4);
}

.kl-clicked {
	background-color: var(--main-color-2) !important;
	color: var(--main-color-text-dark) !important;
}

@media (pointer: none), (pointer: coarse) {
	.kl-wrap-content h4 {
		margin: 10px 0px;
		font-size: 14px;
		font-weight: normal;
		color: var(--main-color-1);
	}
	.kl-keyword-list-element {
		width: 90px;
		height: 40px;
		margin: 5px;
		padding: 2px 5px;
		display: inline-block;
		border: 2px solid var(--main-color-border-dark);
		border-radius: 10px;
		font-size: 12px;
		background-color: var(--main-color-4);
		color: var(--main-color-text-light);
		vertical-align: top;
		text-align: center;
		position: relative;
	}
}
</style>
