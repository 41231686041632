<template>
	<div class="cl-wrap-content">
		<h4>
			{{ $t('clClassifiers') }}
			<i class="fas fa-info-circle" @click="$emit('showInfo', { title: 'clClassifiers', text: 'clClassifiersInfo' })"></i>
		</h4>
		<div class="cl-wrap-list">
			<div :id="'all-classifiers'" class="cl-class-list-element" @click="$emit('setClassifier', $event, 'all')">
				{{ $t('clAll') }}
			</div>
			<div
				v-for="classifier in classifiers"
				:key="classifier.classifier"
				class="cl-class-list-element"
				@click="$emit('setClassifier', $event, classifier)"
			>
				<p v-if="classifier.classifier !== 'KNN'">
					{{ $t(classifier.name) }}
				</p>
				<p v-else v-html="$t('clKNNHyphen')"></p>
				<!-- <i
					class="fas fa-info-circle"
					@click="$emit('showInfo', { title: classifier.name, text: classifier.description })"
				></i> -->
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Prediction
 * A list of all available classifiers
 */
export default {
	name: 'ClassifierList',
	props: {
		// The available classifiers
		classifiers: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style scoped>
.cl-wrap-content h4 {
	margin-top: 10px;
	font-size: 20px;
	font-weight: normal;
	color: var(--main-color-1);
}

.cl-wrap-content i:hover {
	cursor: pointer;
	color: var(--main-color-1-transparent);
}

.cl-wrap-list {
	width: 90%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin: auto;
	padding: 0px 10px;
}

.cl-class-list-element,
.cl-keyword-list-element {
	width: 150px;
	height: 40px;
	margin: 5px;
	padding: 2px 5px;
	display: inline-block;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 10px;
	font-size: 17px;
	background-color: var(--main-color-4);
	color: var(--main-color-text-light);
	vertical-align: top;
	text-align: center;
	position: relative;
}

.cl-class-list-element:hover,
.cl-keyword-list-element:hover {
	cursor: pointer;
	background-color: var(--main-color-2);
	color: var(--main-color-text-dark);
}
.cl-class-list-element i,
.cl-keyword-list-element i {
	position: absolute;
	top: 2px;
	right: 2px;
}

.cl-class-list-element:hover i:hover {
	color: var(--main-color-4);
}

.cl-clicked {
	background-color: var(--main-color-2) !important;
	color: var(--main-color-text-dark) !important;
}

@media (pointer: none), (pointer: coarse) {
	.cl-wrap-content h4 {
		margin: 10px 0px;
		font-size: 14px;
		font-weight: normal;
		color: var(--main-color-1);
	}
	.cl-class-list-element {
		width: 90px;
		height: 45px;
		margin: 5px;
		padding: 2px 5px;
		display: inline-block;
		border: 2px solid var(--main-color-border-dark);
		border-radius: 10px;
		font-size: 12px;
		background-color: var(--main-color-4);
		color: var(--main-color-text-light);
		vertical-align: top;
		text-align: center;
		position: relative;
	}
}
</style>
