<template>
	<div class="ln-wrap-content">
		<h1>{{ $t('heImpressum') }}</h1>
		<h2>{{ $t('lnServiceProvider') }}</h2>
		<p>Philip Storz</p>
		<p>{{ $t('foReutlingenUniversity') }}</p>
		<p>Alteburgstraße 150</p>
		<p>72762 Reutlingen</p>
		<p>{{ $t('lnGermany') }}</p>

		<h2>{{ $t('lnContact') }}</h2>
		<p>{{ $t('lnEmail') }}:</p>
		<p>philip.storz@reutlingen-university.de</p>

		<h2>{{ $t('lnLiability') }}</h2>
		<h3>{{ $t('lnDisclaimer') }}</h3>
		<p>{{ $t('lnLiabilityPart1') }}</p>
		<h3>{{ $t('lnLinks') }}</h3>
		<p>{{ $t('lnLiabilityPart2') }}</p>
		<h3>{{ $t('lnCopyright') }}</h3>
		<p>{{ $t('lnLiabilityPart3') }}</p>
		<h3>{{ $t('lnInfingement') }}</h3>
		<p>{{ $t('lnLiabilityPart4') }}</p>
		<p>
			{{ $t('lnCreatedWith') }}
			<a href="https://datenschutz-generator.de/" :title="$t('lnInfo')" target="_blank" rel="noopener noreferrer nofollow"
				>Datenschutz-Generator.de</a
			>
			{{ $t('lnBy') }} Dr. Thomas Schwenke
		</p>
		<h2>{{ $t('lnUsedMedia') }}</h2>
		<p>
			<a href="https://phil.cdc.gov/details.aspx?pid=23312" target="_blank">
				<i class="fas fa-external-link-alt"></i>
			</a>
			SARS-CoV-2
		</p>
		<p>
			<a href="https://www.freepnglogos.com/pics/logo-twitter-png" target="_blank">
				<i class="fas fa-external-link-alt"></i>
			</a>
			Twitter Logo
		</p>
		<p>
			<a href="https://pngset.com/download-free-png-qrenn" target="_blank">
				<i class="fas fa-external-link-alt"></i>
			</a>
			Google Logo
		</p>
		<p>
			<a href="https://commons.wikimedia.org/wiki/File:Flag_of_Germany.svg" target="_blank">
				<i class="fas fa-external-link-alt"></i>
			</a>
			{{ $t('lnGermanFlag') }}
		</p>
		<p>
			<a href="https://commons.wikimedia.org/wiki/File:Flag_of_the_United_Kingdom_(3-5).svg" target="_blank">
				<i class="fas fa-external-link-alt"></i>
			</a>
			{{ $t('lnUKFlag') }}
		</p>
	</div>
</template>

<script>
/**
 * @group LegalNotice
 * Displays the legal notice
 */
export default {
	name: 'LegalNotice',
};
</script>

<style scoped>
.ln-wrap-content {
	width: calc(100% - 100px);
	margin: auto;
}

.ln-wrap-content h1 {
	text-align: center;
	font-size: 50px;
}

.ln-wrap-content h2 {
	font-size: 30px;
	margin: 10px 0px;
}

.ln-wrap-content h3 {
	font-size: 20px;
	margin: 10px 0px;
}

.ln-wrap-content p {
	font-size: 20px;
	margin-top: 10px;
	color: var(--main-color-1);
	-ms-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.ln-wrap-content a {
	color: var(--main-color-2);
	text-decoration: none;
}

.ln-wrap-content a:hover {
	text-decoration: underline;
}
</style>
