import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { global } from './global.js';
import i18n from '@/translations/i18n';
import * as uuid from 'uuid';
import VueCompositionAPI from '@vue/composition-api';

Vue.prototype.$global = global;
Vue.prototype.$uuid = uuid;

Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);

new Vue({
	router,
	i18n,
	render: function (h) {
		return h(App);
	},
}).$mount('#app');
