<template>
	<div class="fo-wrap-content">
		<h4>COVID-19 Observation Germany</h4>
		<div class="fo-wrap-links">
			<p v-if="$route.name != 'LegalNotice'">
				<router-link class="fo-footer-element" to="/LegalNotice">{{ $t('heImpressum') }}</router-link> |
			</p>
			<p v-else>
				<router-link class="fo-footer-element" to="/">{{ $t('heHome') }}</router-link> |
			</p>
			<a
				class="fo-footer-element"
				target="_blank"
				rel="noopener noreferrer"
				href="https://www.inf.reutlingen-university.de/home/"
			>
				{{ $t('foReutlingenUniversity') }}</a
			>
			|
			<a
				class="fo-footer-element"
				target="_blank"
				rel="noopener noreferrer"
				href="https://artemis.reutlingen-university.de/"
				>{{ $t('foResearchGroup') }}</a
			>
		</div>
	</div>
</template>

<script>
/**
 * @group Layout
 * The footer of the page
 */
export default {
	name: 'Footer',
};
</script>

<style scoped>
.fo-wrap-content {
	width: 90%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	margin: 60px auto 0px auto;
	padding: 10px;
	text-align: center;
	background-color: var(--main-color-3);
	border-top: 1px solid var(--main-color-border-dark);
}

.fo-wrap-content h4 {
	font-size: 25px;
	margin: 5px auto;
}

.fo-wrap-links {
	font-size: 20px;
	color: var(--main-color-0);
}

.fo-wrap-links p {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	margin: 0px;
	padding: 0px;
	display: inline-block;
}

.fo-footer-element {
	font-size: 20px;
	color: var(--main-color-1);
	text-decoration: none;
	padding: 2px 5px;
}

.fo-footer-element:hover {
	text-decoration: underline;
}
</style>
